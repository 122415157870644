import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { StringUtils, NotFound } from "@ko2-consulting/leaf";
import { useClients, useProjects, useLoggedUser } from "../hooks";
import CircularCard from "../components/CircularCard";
import SquareCard from "../components/SquareCard";
import CreateOrEditClientModal from "../components/CreateOrEditClientModal";
import CreateOrEditProjectModal from "../components/CreateOrEditProjectModal";
import projectsSlice from "../redux/projects/slice";

const useStyles = createUseStyles({
  grid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  emptyItem: {
    height: 0,
    width: "16rem",
  },
  "@media (max-width: 768px)": {
    grid: {
      justifyContent: "center",
    },
  },
  addButton: {
    color: "#FFFFFF",
  },
  cardLink: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    "&:focus": {
      outline: "none",
    },
  },
});

const getProjectFooterColor = (project: any): string => {
  switch (project.status) {
    case "PLANNING":
      return "#25a2b7";
    case "ACTIVE":
      return "#30a64a";
    case "ANALYZING":
      return "#167ffb";
    default:
      return "#495057";
  }
};

const ProjectsPage = () => {
  const classes = useStyles();
  const clients = useClients();
  const projects = useProjects();
  const loggedUser = useLoggedUser();
  const [showCreateOrEditClientModal, setShowCreateOrEditClientModal] =
    useState<boolean>(false);
  const [showCreateOrEditProjectModal, setShowCreateOrEditProjectModal] =
    useState<boolean>(false);
  const [clientIdToCreateOrEdit, setClientIdToCreateOrEdit] = useState<
    number | null
  >(null);
  const [projectIdToCreateOrEdit, setProjectIdToCreateOrEdit] = useState<
    number | null
  >(null);
  const [entityNotFound, setEntityNotFound] = useState<boolean>(false);
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { clientName, projectName } = useParams() as any;

  const handleCreateOrEditClientModalShow = (clientId: number | null) => {
    setClientIdToCreateOrEdit(clientId);
    setShowCreateOrEditClientModal(true);
  };

  const handleCreateOrEditClientModalHide = () => {
    history.push("/dashboard");
    setClientIdToCreateOrEdit(null);
    setShowCreateOrEditClientModal(false);
  };

  const handleCreateOrEditProjectModalShow = (projectId: number | null) => {
    setProjectIdToCreateOrEdit(projectId);
    setShowCreateOrEditProjectModal(true);
    dispatch(projectsSlice.actions.setCreatedProjectId(null));
  };

  const handleCreateOrEditProjectModalHide = () => {
    history.push("/dashboard");
    setProjectIdToCreateOrEdit(null);
    setShowCreateOrEditProjectModal(false);
  };

  const onProjectSelect = (projectId: number) => {
    handleCreateOrEditClientModalHide();
    const name: string | undefined = projects.data.find(
      (project: any) => project.id === projectId
    )?.name;
    if (name) {
      history.push(`/p/${name}`);
    }
  };

  useEffect(() => {
    if (
      location.pathname.startsWith("/c/") &&
      !clients.loading &&
      !showCreateOrEditClientModal
    ) {
      const clientId: number | undefined = clients.data.find(
        (client: any) => client.name === clientName
      )?.id;
      if (clientId) {
        handleCreateOrEditClientModalShow(clientId);
        setEntityNotFound(false);
      } else {
        setEntityNotFound(true);
      }
    } else {
      setEntityNotFound(false);
    }
  }, [location, clients, showCreateOrEditClientModal]);

  useEffect(() => {
    if (
      location.pathname.startsWith("/p/") &&
      !projects.loading &&
      !showCreateOrEditProjectModal
    ) {
      const projectId: number | undefined = projects.data.find(
        (project: any) => project.name === projectName
      )?.id;
      if (projectId) {
        handleCreateOrEditProjectModalShow(projectId);
        setEntityNotFound(false);
      } else {
        setEntityNotFound(true);
      }
    } else {
      setEntityNotFound(false);
    }
  }, [location, projects, showCreateOrEditProjectModal]);

  const renderEmptyItem = () => (
    <div className={classnames(classes.emptyItem, "ml-2 mr-2")} />
  );

  const countQuestionnairesBySubmitState = (
    project: any,
    submitState: string
  ): number =>
    project.questionnaires?.filter(
      (questionnaire: any) => questionnaire.submitState === submitState
    ).length || 0;

  return (
    <>
      {entityNotFound ? (
        <Row className="mb-4">
          <Col
            xs={{ span: 10, offset: 1 }}
            className="d-flex justify-content-center align-items-center"
          >
            <NotFound />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="mb-4">
            <Col
              xs={{ span: 10, offset: 1 }}
              className="d-flex justify-content-between align-items-center"
            >
              <h1>Projects</h1>
              <Button
                className={classes.addButton}
                variant="warning"
                onClick={() => handleCreateOrEditProjectModalShow(null)}
              >
                New Project
              </Button>
            </Col>
          </Row>
          <Row>
            {projects.loading && (
              <Col
                xs={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Spinner animation="grow" variant="warning" />
              </Col>
            )}
            <Col xs={{ span: 10, offset: 1 }}>
              <Row>
                <Col
                  xs={12}
                  className={classnames(
                    "mb-5 flex-wrap pl-0 pr-0 mt-2",
                    classes.grid
                  )}
                >
                  {projects.data.map((project: any) => (
                    <a
                      key={project.id}
                      className={classes.cardLink}
                      href={`/p/${project.name}`}
                      onClick={(event: any) => {
                        event.preventDefault();
                        history.push(`/p/${project.name}`);
                      }}
                    >
                      <SquareCard
                        title={project.title}
                        smallTitle={project.client?.title}
                        className="ml-2 mr-2 mb-5"
                        footerText={`${StringUtils.capitalize(
                          project.status.toLowerCase()
                        )} / ${StringUtils.buildCountSentence(
                          project.projectSections?.length || 0,
                          "Section"
                        )} / ${StringUtils.buildCountSentence(
                          project.projectSections
                            ?.map(
                              (projectSection: any) =>
                                projectSection.section.questions
                            )
                            .flat().length || 0,
                          "Question"
                        )}`}
                        footerBackgroundColor={getProjectFooterColor(project)}
                        bodyTexts={[
                          `${StringUtils.buildCountSentence(
                            project.questionnaires?.length || 0,
                            "Respondent"
                          )} • ${countQuestionnairesBySubmitState(
                            project,
                            "EMPTY"
                          )} Empty
                      `,
                          `${countQuestionnairesBySubmitState(
                            project,
                            "STARTED"
                          )} In Progress • ${countQuestionnairesBySubmitState(
                            project,
                            "SUBMITTED"
                          )} Submitted`,
                        ]}
                        bodyFinalText={`Updated ${project.lastChange}`}
                      />
                    </a>
                  ))}
                  {/* empty divs to fill last row */}
                  {renderEmptyItem()}
                  {renderEmptyItem()}
                  {renderEmptyItem()}
                  {renderEmptyItem()}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col
              xs={{ span: 10, offset: 1 }}
              className="d-flex justify-content-between align-items-center"
            >
              <h1>Clients</h1>
              {loggedUser.role === "SUPER_ADMIN" && (
                <Button
                  className={classes.addButton}
                  variant="warning"
                  onClick={() => handleCreateOrEditClientModalShow(null)}
                >
                  New Client
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            {clients.loading && (
              <Col
                xs={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Spinner animation="grow" variant="warning" />
              </Col>
            )}
            <Col xs={{ span: 10, offset: 1 }}>
              <Row>
                <Col
                  xs={12}
                  className={classnames(
                    "mb-5 flex-wrap pl-0 pr-0 mt-2",
                    classes.grid
                  )}
                >
                  {clients.data.map((client: any) => (
                    <a
                      key={client.id}
                      className={classes.cardLink}
                      href={`/c/${client.name}`}
                      onClick={(event: any) => {
                        event.preventDefault();
                        history.push(`/c/${client.name}`);
                      }}
                    >
                      <CircularCard
                        title={client.title}
                        subtitle={StringUtils.buildCountSentence(
                          client.projects?.length || 0,
                          "Project"
                        )}
                        className="ml-2 mr-2 mb-5"
                      />
                    </a>
                  ))}
                  {/* empty divs to fill last row */}
                  {renderEmptyItem()}
                  {renderEmptyItem()}
                  {renderEmptyItem()}
                  {renderEmptyItem()}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}

      {showCreateOrEditClientModal && (
        <CreateOrEditClientModal
          show
          onHide={handleCreateOrEditClientModalHide}
          clientId={clientIdToCreateOrEdit}
          onProjectSelect={onProjectSelect}
          clients={clients.data}
        />
      )}

      {showCreateOrEditProjectModal && (
        <CreateOrEditProjectModal
          show
          onHide={handleCreateOrEditProjectModalHide}
          projectId={projectIdToCreateOrEdit}
          projects={projects.data}
        />
      )}
    </>
  );
};

export default ProjectsPage;
