import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../redux/reducer";
import sectionsSlice from "../redux/sections/slice";

const useSections = () => {
  const sections = useSelector<RootState, any>((state) => state.sections);
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (sections.data.length === 0) {
      dispatch(sectionsSlice.actions.fetch());
    }
  }, []);

  return sections;
};

export default useSections;
