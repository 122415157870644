import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";
import reducer from "./reducer";
import saga from "./saga";

const sagaMiddleware: SagaMiddleware = createSagaMiddleware();

export default configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(saga);
