import Table from "react-bootstrap/Table";
import { createUseStyles } from "react-jss";
import UsersTableRow from "./UsersTableRow";

const useStyles = createUseStyles({
  table: {
    backgroundColor: "#ffffff",
  },
  "@media (max-width: 992px)": {
    table: {
      overflow: "auto",
    },
  },
});

interface Props {
  users: any[];
}

const UsersTable = ({ users }: Props) => {
  const classes = useStyles();
  return (
    <Table bordered className={classes.table}>
      <thead>
        <tr>
          <th>Username</th>
          <th>Role</th>
          <th>Email</th>
          <th>Password Reset</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user: any) => (
          <UsersTableRow key={user.id} user={user} />
        ))}
      </tbody>
    </Table>
  );
};

export default UsersTable;
