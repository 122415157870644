export default {
  sections: {
    get: "/sections",
    getById: "/sections/:id",
    copy: "/sections/:id/copy",
    copyQuestion: "/sections/:id/questions/:questionId/copy",
    update: "/sections",
  },
  questions: {
    update: "/questions",
    findById: "/questions/:id",
  },
  answerOptions: {
    update: "/answer-options",
    create: "/answer-options",
    delete: "/answer-options/:id",
    hasAnswers: "/answer-options/:id/answered-status",
  },
  reflexiveRules: {
    create: "/reflexive-rules",
    update: "/reflexive-rules",
    delete: "/reflexive-rules/:id",
  },
  clients: {
    get: "/clients",
    create: "/clients",
    update: "/clients",
    copy: "/clients/:id/copy",
    deleteAllData: "/clients/me",
  },
  projects: {
    get: "/projects",
    create: "/projects",
    update: "/projects",
    copy: "/projects/:id/copy",
    addSection: "/projects/:id/sections/:sectionId",
    removeSection: "/projects/:id/sections/:sectionId",
    generateExport: "/projects/:id/export",
    availableFiles: "/projects/:id/files",
    changeSectionOrder: "/projects/:id/sections/:sectionId/order",
  },
  questionnaires: {
    create: "/questionnaires",
    update: "/questionnaires",
  },
  users: {
    findAvailableSuperUsers: "/users?availableSuperUsers=true",
    findBackofficeUsers: "/users?backofficeUsers=true",
    update: "/users",
    create: "/users",
    delete: "/users/:id",
  },
  files: {
    download: "/files?filename=:filename&questionnaireId=:questionnaireId",
  },
  extraRepresentatives: {
    createOrUpdate: "/extra-representatives",
  },
};
