import {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  createRef,
} from "react";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import FormText from "react-bootstrap/FormText";
import Button from "react-bootstrap/Button";
import { createUseStyles } from "react-jss";
import passwordGenerator from "generate-password";
import classnames from "classnames";
import { NoData } from "@ko2-consulting/leaf";
import QuestionnaireEditRow from "./QuestionnaireEditRow";

const useStyles = createUseStyles({
  tableContainer: {
    fontSize: "0.9rem",
  },
  "@media (max-width: 992px)": {
    tableContainer: {
      overflow: "auto",
    },
  },
  addFormsInputGroup: {
    width: "30%",
  },
});

interface Props {
  questionnaires: any[];
  editMode: boolean;
  questionnairesToCreate: any[];
  questionnairesToUpdate: any[];
  onQuestionnairesToCreateChange: Function;
  onQuestionnairesToUpdateChange: Function;
  projectId: number;
  idsWithError: number[];
  onIdsWithErrorChange: Function;
  tableClassName?: string;
  passwordEnabled: boolean;
  client?: any;
  setQuestionnaireIdToRevertSubmit: Function;
}

const QuestionnairesTable = forwardRef(
  (
    {
      questionnaires,
      editMode,
      questionnairesToCreate,
      questionnairesToUpdate,
      onQuestionnairesToCreateChange,
      onQuestionnairesToUpdateChange,
      projectId,
      idsWithError,
      onIdsWithErrorChange,
      tableClassName,
      passwordEnabled,
      client,
      setQuestionnaireIdToRevertSubmit,
    }: Props,
    ref: any
  ) => {
    const classes = useStyles();
    const [questionnaireQuantityToAdd, setQuestionnaireQuantityToAdd] =
      useState<string>("0");
    const rowRefs = useRef<any>([]);

    useImperativeHandle(ref, () => ({
      reset: () => {
        rowRefs.current.forEach((r: any) => r?.current?.reset());
      },
    }));

    const addOrUpdateQuestionnaireInArray = (
      questionnaire: any,
      array: Array<any>
    ): Array<any> => {
      const newQuestionnairesArray = [...array];
      const questionnaireToUpdate = newQuestionnairesArray.find(
        (element) => element.id === questionnaire.id
      );
      if (questionnaireToUpdate) {
        questionnaireToUpdate.name = questionnaire.name;
        questionnaireToUpdate.password = questionnaire.password;
        questionnaireToUpdate.archived = questionnaire.archived;
        questionnaireToUpdate.userId = questionnaire.userId;
        questionnaireToUpdate.link = questionnaire.link;
      } else {
        newQuestionnairesArray.push(questionnaire);
      }
      return newQuestionnairesArray;
    };

    const addQuestionnairesToCreate = (questionnairesArray: any[]) => {
      let newQuestionnairesToCreate = [...questionnairesToCreate];

      questionnairesArray.forEach((questionnaireToCreate: any) => {
        newQuestionnairesToCreate = addOrUpdateQuestionnaireInArray(
          {
            id: questionnaireToCreate.id,
            name: questionnaireToCreate.name,
            password: questionnaireToCreate.password,
            projectId: questionnaireToCreate.projectId,
            isNew: true,
            link: questionnaireToCreate.link,
          },
          newQuestionnairesToCreate
        );
      });
      onQuestionnairesToCreateChange(newQuestionnairesToCreate);
    };

    const addQuestionnaireToUpdate = (
      id: number,
      name?: string,
      password?: string,
      archived?: boolean,
      userId?: number,
      link?: string
    ) => {
      const newQuestionnairesToUpdate = addOrUpdateQuestionnaireInArray(
        {
          id,
          name,
          password,
          projectId,
          archived,
          userId,
          link,
        },
        questionnairesToUpdate
      );
      onQuestionnairesToUpdateChange(newQuestionnairesToUpdate);
    };

    const onQuestionnaireChange = (
      id: number,
      name: string,
      password: string,
      isNew: boolean | undefined,
      archived: boolean | undefined,
      userId: number | undefined,
      link: string | undefined
    ) => {
      if (isNew) {
        if (archived) {
          onQuestionnairesToCreateChange(
            questionnairesToCreate.filter(
              (questionToCreate: any) => questionToCreate.id !== id
            )
          );
        } else {
          addQuestionnairesToCreate([{ id, name, password, link }]);
        }
      } else {
        addQuestionnaireToUpdate(id, name, password, archived, userId, link);
      }
    };

    const onAddButtonClick = () => {
      const questionnairesArray: any[] = Array.from(
        Array(Number(questionnaireQuantityToAdd)).keys()
      ).map((key: number) => ({
        id: Math.random(),
        name: `${client.name}-respondant-${
          key + 1
        }-${passwordGenerator.generate({
          length: 6,
          numbers: true,
          strict: true,
        })}`,
        password: passwordGenerator.generate({
          length: 8,
          numbers: true,
          strict: true,
        }),
      }));

      addQuestionnairesToCreate(questionnairesArray);
      setQuestionnaireQuantityToAdd("0");
    };

    const onQuestionnaireQuantityChange = (event: any) => {
      const value = event.target.value.replace(".", "");
      if (value.length === 0 || !Number.isNaN(Number(value))) {
        setQuestionnaireQuantityToAdd(value);
      } else {
        setQuestionnaireQuantityToAdd(questionnaireQuantityToAdd);
      }
    };

    const questionnairesToShow = questionnaires?.filter(
      (questionnaire: any) =>
        !questionnairesToUpdate.some(
          (questionnaireToUpdate: any) =>
            questionnaireToUpdate.id === questionnaire.id &&
            questionnaireToUpdate.archived
        )
    );

    return (
      <div className={classes.tableContainer}>
        {(questionnairesToShow?.length || 0) + questionnairesToCreate.length >
        0 ? (
          <Table bordered className={classnames("border-dark", tableClassName)}>
            <thead>
              <tr>
                <th className="w-25">ID</th>
                {passwordEnabled && <th className="w-25">Password</th>}
                <th className="w-25">Status</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {questionnairesToShow?.map((questionnaire: any) => {
                const r = createRef();
                rowRefs.current.push(r);
                return (
                  <QuestionnaireEditRow
                    key={questionnaire.id}
                    questionnaire={{
                      id: questionnaire.id,
                      name: questionnaire.name,
                      link: questionnaire.link,
                      password: questionnaire.user?.password,
                      projectId: questionnaire.projectId,
                      userId: questionnaire.user?.id,
                      submitState: questionnaire.submitState,
                    }}
                    onChange={onQuestionnaireChange}
                    editMode={editMode}
                    idsWithError={idsWithError}
                    onIdsWithErrorChange={onIdsWithErrorChange}
                    passwordEnabled={passwordEnabled}
                    ref={r}
                    setQuestionnaireIdToRevertSubmit={
                      setQuestionnaireIdToRevertSubmit
                    }
                  />
                );
              })}
              {questionnairesToCreate.map((questionnaire: any) => (
                <QuestionnaireEditRow
                  key={questionnaire.id}
                  questionnaire={questionnaire}
                  onChange={onQuestionnaireChange}
                  editMode={editMode}
                  idsWithError={idsWithError}
                  onIdsWithErrorChange={onIdsWithErrorChange}
                  passwordEnabled={passwordEnabled}
                  setQuestionnaireIdToRevertSubmit={
                    setQuestionnaireIdToRevertSubmit
                  }
                />
              ))}
            </tbody>
          </Table>
        ) : (
          !editMode && (
            <NoData
              title="This Project could use some Respondents."
              description="Make one by editing this Project."
            />
          )
        )}

        {editMode && (
          <InputGroup
            className={classnames("mt-4", classes.addFormsInputGroup)}
          >
            <FormControl
              value={questionnaireQuantityToAdd}
              onChange={onQuestionnaireQuantityChange}
              className="border-dark"
              disabled={!client}
            />
            <InputGroup.Append>
              <Button
                variant="primary"
                onClick={onAddButtonClick}
                disabled={questionnaireQuantityToAdd.length === 0 || !client}
              >
                Add Respondents
              </Button>
            </InputGroup.Append>
            <FormText muted className="mt-3">
              To add Respondents please first assign this Project to a Client
            </FormText>
          </InputGroup>
        )}
      </div>
    );
  }
);

QuestionnairesTable.defaultProps = {
  tableClassName: undefined,
  client: undefined,
};

export default QuestionnairesTable;
