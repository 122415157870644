import { all } from "redux-saga/effects";
import sectionsSaga from "./sections/saga";
import clientsSaga from "./clients/saga";
import projectsSaga from "./projects/saga";
import usersSaga from "./users/saga";

function* rootSaga() {
  yield all([sectionsSaga(), clientsSaga(), projectsSaga(), usersSaga()]);
}

export default rootSaga;
