import { useEffect } from "react";
import FormControl from "react-bootstrap/FormControl";
import dayjs from "dayjs";
import classnames from "classnames";

interface Props {
  id: string;
  className?: string;
  onChange: Function;
  placeholder?: string;
  value: string | undefined;
}

const DateInput = ({ id, className, onChange, placeholder, value }: Props) => {
  const inputId: string = `date-${id}`;

  useEffect(() => {
    const $input = (window as any).$(`#${inputId}`);

    $input.datepicker().on("changeDate", (e: any) => {
      const formattedDate = dayjs(e.date).format("MM/DD/YYYY");
      onChange(formattedDate);
      $input.datepicker("hide");
    });
  }, []);

  return (
    <FormControl
      id={inputId}
      className={classnames("border-dark", className)}
      placeholder={placeholder}
      data-provide="datepicker"
      data-date-format="mm/dd/yyyy"
      value={value}
    />
  );
};

DateInput.defaultProps = {
  placeholder: null,
  className: null,
};

export default DateInput;
