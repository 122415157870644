import { memo } from "react";
import Card from "react-bootstrap/Card";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles({
  card: {
    width: "15rem",
    height: "15rem",
    borderRadius: "7.5rem",
    cursor: "pointer",
    transition: "0.25s",
    "&:hover": {
      transform: "translateY(-0.75rem)",
    },
  },
  subtitle: {
    color: "#6d767e",
  },
});

interface Props {
  title: string;
  subtitle: string;
  className?: string;
}

const CircularCard = ({ className, title, subtitle }: Props) => {
  const classes = useStyles();
  return (
    <Card className={classnames(classes.card, "shadow", className)}>
      <Card.Body className="d-flex justify-content-center align-items-center flex-column">
        <h4 className="text-center">{title}</h4>
        <span className="text-center text-dark">{subtitle}</span>
      </Card.Body>
    </Card>
  );
};

CircularCard.defaultProps = {
  className: null,
};

export default memo(CircularCard);
