/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  data: any[];
  isLoading: boolean;
  error: Error | null;
}

export default createSlice({
  name: "users",
  initialState: {
    data: [],
    isLoading: true,
    error: null,
  } as InitialState,
  reducers: {
    backofficeUsersFetch: (state) => state,

    addBackofficeUsers: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
    }),

    addError: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: false,
    }),

    updateUserFetch: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    updateUser: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      const userToUpdate = newState.data.find(
        (user: any) => user.id === action.payload.id
      );
      Object.assign(userToUpdate, action.payload);
      newState.isLoading = false;
      return newState;
    },

    createUserFetch: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    addUser: (state, action) => ({
      ...state,
      data: [...state.data, action.payload],
      isLoading: false,
    }),

    deleteUserFetch: (state, action) => state,

    deleteUser: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.data = newState.data.filter(
        (user: any) => user.id !== action.payload
      );
      return newState;
    },
  },
});
