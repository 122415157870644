import { useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useLocation, useHistory } from "react-router-dom";
import { SectionsList } from "@ko2-consulting/leaf";
import { useSections, useProjects, useLoggedUser } from "../hooks";
import sectionsSlice from "../redux/sections/slice";
import projectsSlice from "../redux/projects/slice";
import { api, httpClient } from "../utils";

const qs = require("query-string");

const QuestionsPage = () => {
  const sections = useSections();
  const projects = useProjects();
  const loggedUser = useLoggedUser();
  const sectionsListRef = useRef<any>();
  const dispatch: Dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { sectionName } = qs.parse(location.search);

  useEffect(() => {
    if (sectionName && sections.data.length > 0) {
      document.getElementById(sectionName)?.scrollIntoView();
      sectionsListRef.current?.openSection(
        sections.data.find((section: any) => section.name === sectionName).id
      );
      history.push("/question-sets");
    }
  }, [sections]);

  const onCopySectionPress = (sectionId: number) =>
    dispatch(sectionsSlice.actions.copyFetch(sectionId));

  const onCopyQuestionPress = (sectionId: number, questionId: number) =>
    dispatch(
      sectionsSlice.actions.copyQuestionFetch({ sectionId, questionId })
    );

  const onEditSectionSubmit = (sectionData: any) =>
    dispatch(sectionsSlice.actions.updateFetch(sectionData));

  const onEditQuestionSubmit = (questionData: any) =>
    dispatch(sectionsSlice.actions.updateQuestionFetch(questionData));

  const onEditAnswerOptionSubmit = (answerOptionData: any) =>
    dispatch(sectionsSlice.actions.updateAnswerOptionFetch(answerOptionData));

  const onCreateAnswerOptionSubmit = (answerOption: any) =>
    dispatch(sectionsSlice.actions.createAnswerOptionFetch(answerOption));

  const onAnswerOptionDelete = (answerOptionId: number) =>
    dispatch(sectionsSlice.actions.deleteAnswerOptionFetch(answerOptionId));

  const onArchiveSectionPress = (sectionId: number) =>
    dispatch(sectionsSlice.actions.archiveFetch(sectionId));

  const onArchiveQuestionPress = (questionId: number, sectionId: number) =>
    dispatch(
      sectionsSlice.actions.archiveQuestionFetch({ questionId, sectionId })
    );

  const onSectionOrderChange = (section: any, direction: "UP" | "DOWN") =>
    dispatch(sectionsSlice.actions.changeOrderFetch({ section, direction }));

  const onQuestionOrderChange = (
    section: any,
    question: any,
    direction: "UP" | "DOWN"
  ) =>
    dispatch(
      sectionsSlice.actions.changeQuestionOrderFetch({
        section,
        question,
        direction,
      })
    );

  const onCreateReflexiveRule = (reflexiveRule: any) => {
    dispatch(sectionsSlice.actions.createReflexiveRuleFetch(reflexiveRule));
  };

  const onUpdateReflexiveRule = (reflexiveRule: any) =>
    dispatch(sectionsSlice.actions.updateReflexiveRuleFetch(reflexiveRule));

  const onDeleteReflexiveRule = (reflexiveRuleId: number) =>
    dispatch(sectionsSlice.actions.deleteReflexiveRuleFetch(reflexiveRuleId));

  const onQuestionUpdateFinished = (questionId: number) =>
    dispatch(sectionsSlice.actions.singleQuestionFetch(questionId));

  const onEditSectionHide = () => {
    if (sections.sectionNameExists) {
      dispatch(sectionsSlice.actions.setSectionNameExists(false));
    }
  };

  const onAddSectionToProject = (projectId: number, sectionId: number) =>
    dispatch(
      projectsSlice.actions.addSectionFetch({ id: projectId, sectionId })
    );

  const optionHasAnswers = async (id: number): Promise<boolean> => {
    const { data } = await httpClient.get(
      api.answerOptions.hasAnswers.replace(":id", id.toString())
    );
    return data.hasAnswers;
  };

  return (
    <>
      <Row className="mb-4">
        <Col xs={{ span: 10, offset: 1 }}>
          <h1>Question Sets</h1>
        </Col>
      </Row>
      <Row>
        {sections.loading && (
          <Col
            xs={12}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner animation="grow" variant="warning" />
          </Col>
        )}
        <Col xs={{ span: 10, offset: 1 }}>
          <SectionsList
            ref={sectionsListRef}
            sections={sections.data}
            onCopySectionPress={onCopySectionPress}
            onCopyQuestionPress={onCopyQuestionPress}
            onEditSectionSubmit={onEditSectionSubmit}
            onEditQuestionSubmit={onEditQuestionSubmit}
            onEditAnswerOptionSubmit={onEditAnswerOptionSubmit}
            onCreateAnswerOptionSubmit={onCreateAnswerOptionSubmit}
            onArchiveSectionPress={onArchiveSectionPress}
            onArchiveQuestionPress={onArchiveQuestionPress}
            onSectionOrderChange={onSectionOrderChange}
            onQuestionOrderChange={onQuestionOrderChange}
            onAnswerOptionDelete={onAnswerOptionDelete}
            onCreateReflexiveRule={onCreateReflexiveRule}
            onUpdateReflexiveRule={onUpdateReflexiveRule}
            onDeleteReflexiveRule={onDeleteReflexiveRule}
            onQuestionUpdateFinished={onQuestionUpdateFinished}
            nameAlreadyExists={sections.sectionNameExists}
            questionNameAlreadyExists={sections.questionNameExists}
            onEditSectionHide={onEditSectionHide}
            projects={projects}
            onAddSectionToProject={onAddSectionToProject}
            cardToolbarDisabled={sections.cardToolbarDisabled}
            displayMode={
              loggedUser.role === "SUPER_ADMIN"
                ? "QUESTION_BANK"
                : "QUESTION_BANK_NO_EDITABLE"
            }
            optionHasAnswersCheckFunction={optionHasAnswers}
          />
        </Col>
      </Row>
    </>
  );
};

export default QuestionsPage;
