/* eslint-disable no-param-reassign */
import { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Badge from "react-bootstrap/Badge";
import FormControl from "react-bootstrap/FormControl";
import FormText from "react-bootstrap/FormText";
import Button from "react-bootstrap/Button";
import { Pencil, Briefcase } from "react-bootstrap-icons";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  ModalWithIcon,
  EditableText,
  ErrorTooltip,
  ConfirmActionModal,
  StringUtils,
  DeleteRowButton,
} from "@ko2-consulting/leaf";
import clientsSlice from "../redux/clients/slice";
import { RootState } from "../redux/reducer";
import { useLoggedUser } from "../hooks";
import RepresentativeCard from "./RepresentativeCard";

const INFO_VALUE_MAX_LENGTH: number = 255;

const useStyles = createUseStyles({
  editableGreyText: {
    textAlign: "justify",
    marginBottom: "0.5rem",
  },
  infoInput: {
    "& input": {
      backgroundImage: 'url("") !important',
    },
  },
  textarea: {
    resize: "none",
    height: "100% !important",
  },
  archiveModal: {
    zIndex: 999,
  },
  notesColumn: {},
  "@media (max-width: 992px)": {
    notesColumn: {
      marginTop: "3rem",
    },
  },
  label: {
    fontWeight: "500",
  },
  infoValue: {
    fontWeight: "100",
    fontSize: "1.1rem",
  },
  link: {
    color: "inherit",
    textDecoration: "underline",
    "&:hover": {
      color: "inherit",
    },
  },
  disabledLink: {
    cursor: "default",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  projectBadge: {
    fontSize: "1rem",
    fontWeight: 200,
  },
  modalBody: {
    wordWrap: "break-word",
  },
  representativeContainer: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#979797",
    borderRadius: "20px",
    padding: "0.8rem",
  },
});

interface Props {
  show: boolean;
  onHide: Function;
  clientId: number | null;
  onProjectSelect: Function;
  clients: any[];
}

const normalizeWebsite = (website: string): string => {
  if (!website.includes("http://") || !website.includes("https://")) {
    return `http://${website}`;
  }
  return website;
};

const CreateOrEditClientModal = ({
  show,
  onHide,
  clientId,
  onProjectSelect,
  clients,
}: Props) => {
  const client = clients.find((p: any) => p.id === clientId);
  const loggedUser = useLoggedUser();
  const nameRef = useRef<string>(client?.name || "new-client");
  const titleRef = useRef<string>(client?.title || "New Client");
  const descriptionRef = useRef<string>(
    client && client.description !== null && client.description !== undefined
      ? client.description
      : ""
  );
  const [website, setWebsite] = useState<string>(client?.website);
  const [representativeFirstName, setRepresentativeFirstName] =
    useState<string>(client?.representativeFirstName);
  const [representativeLastName, setRepresentativeLastName] = useState<string>(
    client?.representativeLastName
  );
  const [representativeTitle, setRepresentativeTitle] = useState<string>(
    client?.representativeTitle
  );
  const [representativeEmail, setRepresentativeEmail] = useState<string>(
    client?.representativeEmail
  );
  const [representativePhone, setRepresentativePhone] = useState<string>(
    client?.representativePhone
  );
  const [notes, setNotes] = useState<string>(client?.notes);
  const [superUserId, setSuperUserId] = useState<string>(
    client?.superUserId || "select"
  );
  const [nameHasError, setNameHasError] = useState<boolean>(false);
  const [titleHasError, setTitleHasError] = useState<boolean>(false);
  const [descriptionHasError, setDescriptionHasError] =
    useState<boolean>(false);
  const [websiteHasError, setWebsiteHasError] = useState<boolean>(false);
  const [representativeFirstNameHasError, setRepresentativeFirstNameHasError] =
    useState<boolean>(false);
  const [representativeLastNameHasError, setRepresentativeLastNameHasError] =
    useState<boolean>(false);
  const [representativeTitleHasError, setRepresentativeTitleHasError] =
    useState<boolean>(false);
  const [representativeEmailHasError, setRepresentativeEmailHasError] =
    useState<boolean>(false);
  const [representativePhoneHasError, setRepresentativePhoneHasError] =
    useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(!client?.id);
  const classes = useStyles();
  const dispatch: Dispatch = useDispatch();
  const nameAlreadyExists: boolean = useSelector<RootState, boolean>(
    (state) => state.clients.nameAlreadyExists
  );
  const superUsersToSelect: any[] | null = useSelector<RootState, any[] | null>(
    (state) => state.clients.superUsersToSelect
  );
  const [extraRepresentatives, setExtraRepresentatives] = useState<any[]>(
    client?.extraRepresentatives?.map(
      ({ createdAt, updatedAt, ...rest }: any) => rest
    ) || []
  );

  /**
   * This state variable exists to force a re-render on input change, due a problem with
   * react-editable-content and React hooks.
   */
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState<string>();

  useEffect(() => {
    if (nameAlreadyExists) {
      dispatch(clientsSlice.actions.setNameAlreadyExists(false));
    }
  }, [editMode]);

  useEffect(() => {
    if (editMode) {
      dispatch(clientsSlice.actions.superUsersToSelectFetch());
    }
  }, [editMode]);

  const addExtraRepresentative = (representative: any) =>
    setExtraRepresentatives([...extraRepresentatives, representative]);

  const updateExtraRepresentative = (representative: any) => {
    const existingRepresentatives = [...extraRepresentatives];
    const extraRepresentativeToUpdate = existingRepresentatives.find(
      (r) => r.id === representative.id
    );
    Object.assign(extraRepresentativeToUpdate, representative);
    if (!extraRepresentativeToUpdate.isNew) {
      extraRepresentativeToUpdate.isUpdate = true;
    }
    setExtraRepresentatives(existingRepresentatives);
  };

  const handleOnHide = (isCancel: boolean = false) => {
    if (editMode && client) {
      nameRef.current = isCancel
        ? client.name
        : StringUtils.removeHTMLCharacters(nameRef.current);
      titleRef.current = isCancel
        ? client.title
        : StringUtils.removeHTMLCharacters(titleRef.current);
      descriptionRef.current = isCancel
        ? client.description
        : StringUtils.removeHTMLCharacters(descriptionRef.current);
      setSuperUserId(isCancel ? client.superUserId : superUserId);
      if (isCancel) {
        setExtraRepresentatives(client.extraRepresentatives);
      }
      setEditMode(false);
    } else {
      onHide();
    }
  };

  const onDonePress = () => {
    if (
      !nameHasError &&
      !titleHasError &&
      !descriptionHasError &&
      !websiteHasError &&
      !representativeFirstNameHasError &&
      !representativeLastNameHasError &&
      !representativeTitleHasError &&
      !representativeEmailHasError &&
      !representativePhoneHasError
    ) {
      let clientData: any = {};

      const normalizedName: string = StringUtils.removeHTMLCharacters(
        nameRef.current
      );
      const normalizedTitle: string = StringUtils.removeHTMLCharacters(
        titleRef.current
      );
      const normalizedDescription: string = StringUtils.removeHTMLCharacters(
        descriptionRef.current
      );

      if (client?.id) {
        clientData.id = client.id;

        if (normalizedName !== client.name) {
          clientData.name = normalizedName;
        }
        if (normalizedTitle !== client.title) {
          clientData.title = normalizedTitle;
        }
        if (normalizedDescription !== client.description) {
          clientData.description = normalizedDescription;
        }
        if (website !== client.website) {
          clientData.website = website;
        }
        if (representativeFirstName !== client.representativeFirstName) {
          clientData.representativeFirstName = representativeFirstName;
        }
        if (representativeLastName !== client.representativeLastName) {
          clientData.representativeLastName = representativeLastName;
        }
        if (representativeTitle !== client.representativeTitle) {
          clientData.representativeTitle = representativeTitle;
        }
        if (representativeEmail !== client.representativeEmail) {
          clientData.representativeEmail = representativeEmail;
        }
        if (representativePhone !== client.representativePhone) {
          clientData.representativePhone = representativePhone;
        }
        if (notes !== client.notes) {
          clientData.notes = notes;
        }
        if (superUserId !== client.superUserId) {
          clientData.superUserId =
            superUserId === "select" ? null : superUserId;
        }

        const extraRepresentativesToCreateOrUpdate = extraRepresentatives
          .filter(
            (representative) => representative.isNew || representative.isUpdate
          )
          .map(({ isNew, isUpdate, ...representative }) => {
            if (isNew) {
              delete representative.id;
            }

            if (representative.superUserId === "select") {
              representative.superUserId = null;
            }
            delete representative.superUser;

            return representative;
          });

        if (
          Object.keys(clientData).length > 1 ||
          extraRepresentativesToCreateOrUpdate.length > 0
        ) {
          dispatch(
            clientsSlice.actions.updateFetch({
              clientData,
              extraRepresentatives: extraRepresentativesToCreateOrUpdate,
              callback: handleOnHide,
            })
          );
        } else {
          handleOnHide();
        }
      } else {
        clientData = {
          name: normalizedName,
          title: normalizedTitle,
          description: normalizedDescription,
          website,
          representativeFirstName,
          representativeLastName,
          representativeTitle,
          representativeEmail,
          representativePhone,
          notes,
          superUserId: superUserId !== "select" ? superUserId : null,
        };
        dispatch(
          clientsSlice.actions.createFetch({ clientData, callback: onHide })
        );
      }
    }
  };

  const infoInvalidErrorMessage = (value: string): string => {
    return `Please shorten - ${value?.length}/${INFO_VALUE_MAX_LENGTH}`;
  };

  const onArchive = () => {
    dispatch(clientsSlice.actions.archiveFetch(client.id));
    setShowArchiveModal(false);
    onHide();
  };

  const onCopy = () => {
    dispatch(clientsSlice.actions.copyFetch(client.id));
    onHide();
  };

  const filterSuperUsersToSelect = () =>
    superUsersToSelect?.filter(
      (user) =>
        user.id !== superUserId &&
        extraRepresentatives.every(
          (representative) => representative.superUserId !== user.id
        )
    ) || [];

  const ModalHeaderIcon = editMode ? Pencil : Briefcase;

  return (
    <>
      <ModalWithIcon
        show={showArchiveModal ? false : show}
        onHide={() => handleOnHide(true)}
        onDonePress={onDonePress}
        icon={<ModalHeaderIcon color="#FFFFFF" size="1.4rem" />}
        title={`Editing "${client?.name || "new-client"}"`}
        headerEnabled={editMode}
      >
        <div
          className={classnames(
            "d-flex flex-column align-items-center ml-4 mr-4 mb-4",
            classes.modalBody,
            !editMode ? "mt-2" : null
          )}
        >
          <Row className="w-100">
            <Col
              xs={12}
              className="d-flex justify-content-between align-items-center flex-wrap-reverse"
            >
              <h2 className="w-100">
                {editMode ? (
                  <EditableText
                    textRef={titleRef}
                    onChange={(value: string) => {
                      titleRef.current = value;
                      nameRef.current = StringUtils.normalizeName(value);
                      setName(nameRef.current);
                    }}
                    maxLength={80}
                    onError={(errorMessage: string) =>
                      setTitleHasError(!!errorMessage)
                    }
                  />
                ) : (
                  titleRef.current
                )}
              </h2>
              {editMode ? (
                <EditableText
                  textRef={nameRef}
                  maxLength={40}
                  onChange={(value: string) => {
                    nameRef.current = StringUtils.normalizeName(value);
                  }}
                  onError={(errorMessage: string) =>
                    setNameHasError(!!errorMessage)
                  }
                  customErrorMessage={
                    nameAlreadyExists ? "That name already exists." : undefined
                  }
                />
              ) : (
                nameRef.current
              )}
            </Col>
          </Row>
          <Row className="mt-2 w-100">
            <Col
              xs={12}
              className={classnames(classes.editableGreyText, "text-dark")}
            >
              {editMode ? (
                <EditableText
                  textRef={descriptionRef}
                  onChange={(value: string) => {
                    descriptionRef.current = value;
                  }}
                  maxLength={300}
                  onError={(errorMessage: string) =>
                    setDescriptionHasError(!!errorMessage)
                  }
                  required={false}
                  placeholder="Client Description"
                />
              ) : (
                descriptionRef.current
              )}
            </Col>
          </Row>

          {!editMode && client.projects && (
            <Row className="w-100">
              <Col xs={12}>
                {client.projects.map((project: any) => (
                  <Button
                    key={project.id}
                    className={classnames(
                      "mr-3 mt-3 mw-100",
                      classes.projectBadge
                    )}
                    variant="dark"
                    size="sm"
                    onClick={() => onProjectSelect(project.id)}
                  >
                    {project.title}
                  </Button>
                ))}
              </Col>
            </Row>
          )}

          <Row className={classnames("w-100", editMode ? "mt-2" : "mt-5")}>
            <Col xs={12} lg={7} className="pr-5">
              <h3 className="text-dark">Representatives</h3>

              <Row className={classes.representativeContainer}>
                <Col xs={12}>
                  <FormGroup className={classnames("mt-4", classes.infoInput)}>
                    {editMode ? (
                      <>
                        <FormLabel className={classes.label}>User</FormLabel>
                        <div className="position-relative">
                          <DeleteRowButton
                            onClick={() => setSuperUserId("select")}
                          />
                          <FormControl
                            className="border-dark"
                            custom
                            as="select"
                            onChange={(event: any) =>
                              setSuperUserId(event.target.value)
                            }
                            value={superUserId}
                          >
                            <option disabled value="select">
                              Select an existing SuperUser
                            </option>
                            {client?.superUser && (
                              <option value={client.superUser.id}>
                                {client.superUser.username}
                              </option>
                            )}
                            {!!superUserId && superUserId !== "select" && (
                              <option key={superUserId} value={superUserId}>
                                {
                                  superUsersToSelect?.find(
                                    (user) => user.id === superUserId
                                  )?.username
                                }
                              </option>
                            )}
                            {filterSuperUsersToSelect().map(
                              (superUser: any) => (
                                <option key={superUser.id} value={superUser.id}>
                                  {superUser.username}
                                </option>
                              )
                            )}
                          </FormControl>
                        </div>
                        <ErrorTooltip
                          message={infoInvalidErrorMessage(website)}
                        />
                        <FormText muted className="mt-3">
                          Select an existing SuperUser, not currently assigned
                          to another client. Or create a new one &nbsp;
                          <a
                            href="/users"
                            target="_blank"
                            className={classes.link}
                          >
                            here
                          </a>
                          .
                        </FormText>
                      </>
                    ) : (
                      <Badge
                        className={classnames(classes.infoValue, "p-2")}
                        variant="light"
                      >
                        {client.superUser?.username || "-"}
                      </Badge>
                    )}
                  </FormGroup>
                </Col>

                <Col xs={12} lg={editMode ? 6 : 12}>
                  <FormGroup
                    className={classnames(
                      editMode ? "mt-4" : null,
                      classes.infoInput
                    )}
                  >
                    {editMode ? (
                      <>
                        <FormLabel className={classes.label}>Name</FormLabel>
                        <FormControl
                          className="border-dark"
                          value={representativeFirstName || ""}
                          onChange={(event: any) => {
                            const { value } = event.target;
                            setRepresentativeFirstNameHasError(
                              value.length > INFO_VALUE_MAX_LENGTH
                            );
                            setRepresentativeFirstName(value);
                          }}
                          placeholder="Name"
                        />
                        <ErrorTooltip
                          message={infoInvalidErrorMessage(
                            representativeFirstName
                          )}
                        />
                      </>
                    ) : (
                      <span className={classes.infoValue}>
                        {!representativeFirstName && !representativeLastName
                          ? "-"
                          : `${representativeFirstName || ""} ${
                              representativeLastName || ""
                            }`}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                {editMode && (
                  <Col xs={12} lg={6}>
                    <FormGroup
                      className={classnames(
                        editMode ? "mt-4" : null,
                        classes.infoInput
                      )}
                    >
                      <FormLabel className={classes.label}>Last Name</FormLabel>
                      <FormControl
                        className="border-dark"
                        value={representativeLastName || ""}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          setRepresentativeLastNameHasError(
                            value.length > INFO_VALUE_MAX_LENGTH
                          );
                          setRepresentativeLastName(value);
                        }}
                        placeholder="Last Name"
                      />
                      <ErrorTooltip
                        message={infoInvalidErrorMessage(
                          representativeLastName
                        )}
                      />
                    </FormGroup>
                  </Col>
                )}

                <Col xs={12} lg={editMode ? 6 : 12}>
                  <FormGroup
                    className={classnames(
                      editMode ? "mt-4" : null,
                      classes.infoInput
                    )}
                  >
                    {editMode ? (
                      <>
                        <FormLabel className={classes.label}>Title</FormLabel>
                        <FormControl
                          className="border-dark"
                          value={representativeTitle || ""}
                          onChange={(event: any) => {
                            const { value } = event.target;
                            setRepresentativeTitleHasError(
                              value.length > INFO_VALUE_MAX_LENGTH
                            );
                            setRepresentativeTitle(value);
                          }}
                          placeholder="Title"
                        />
                        <ErrorTooltip
                          message={infoInvalidErrorMessage(representativeTitle)}
                        />
                      </>
                    ) : (
                      <span className={classes.infoValue}>
                        {representativeTitle || "-"}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col xs={12} lg={editMode ? 6 : 12}>
                  <FormGroup
                    className={classnames(
                      editMode ? "mt-4" : null,
                      classes.infoInput
                    )}
                  >
                    {editMode ? (
                      <>
                        <FormLabel className={classes.label}>Website</FormLabel>
                        <FormControl
                          className="border-dark"
                          value={website || ""}
                          onChange={(event: any) => {
                            const { value } = event.target;
                            setWebsiteHasError(
                              value.length > INFO_VALUE_MAX_LENGTH
                            );
                            setWebsite(value);
                          }}
                          placeholder="URL"
                          isInvalid={websiteHasError}
                        />
                        <ErrorTooltip
                          message={infoInvalidErrorMessage(website)}
                        />
                      </>
                    ) : (
                      <a
                        href={website ? normalizeWebsite(website) : "#"}
                        className={classnames(
                          classes.link,
                          !website ? classes.disabledLink : null
                        )}
                        target={website ? "_blank" : "_self"}
                        rel="noreferrer"
                      >
                        <span className={classes.infoValue}>
                          {website || "-"}
                        </span>
                      </a>
                    )}
                  </FormGroup>
                </Col>

                <Col xs={12} lg={editMode ? 6 : 12}>
                  <FormGroup
                    className={classnames(
                      editMode ? "mt-4" : null,
                      classes.infoInput
                    )}
                  >
                    {editMode ? (
                      <>
                        <FormLabel className={classes.label}>Email</FormLabel>
                        <FormControl
                          className="border-dark"
                          value={representativeEmail || ""}
                          onChange={(event: any) => {
                            const { value } = event.target;
                            setRepresentativeEmailHasError(
                              value.length > INFO_VALUE_MAX_LENGTH
                            );
                            setRepresentativeEmail(value);
                          }}
                          placeholder="Email"
                        />
                        <ErrorTooltip
                          message={infoInvalidErrorMessage(representativeEmail)}
                        />
                      </>
                    ) : (
                      <span className={classes.infoValue}>
                        {representativeEmail || "-"}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col xs={12} lg={editMode ? 6 : 12}>
                  <FormGroup
                    className={classnames(
                      editMode ? "mt-4" : null,
                      classes.infoInput
                    )}
                  >
                    {editMode ? (
                      <>
                        <FormLabel className={classes.label}>Phone</FormLabel>
                        <FormControl
                          className="border-dark"
                          value={representativePhone || ""}
                          onChange={(event: any) => {
                            const { value } = event.target;
                            setRepresentativePhoneHasError(
                              value.length > INFO_VALUE_MAX_LENGTH
                            );
                            setRepresentativePhone(value);
                          }}
                          placeholder="Phone"
                        />
                        <ErrorTooltip
                          message={infoInvalidErrorMessage(representativePhone)}
                        />
                      </>
                    ) : (
                      <span className={classes.infoValue}>
                        {representativePhone || "-"}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              {extraRepresentatives.map((extraRepresentative: any) => (
                <RepresentativeCard
                  key={extraRepresentative.id}
                  editMode={editMode}
                  representative={extraRepresentative}
                  filteredSuperUsersToSelect={filterSuperUsersToSelect()}
                  superUsersToSelect={superUsersToSelect}
                  updateExtraRepresentative={updateExtraRepresentative}
                  setEmailHasError={setRepresentativeEmailHasError}
                  setFirstNameHasError={setRepresentativeFirstNameHasError}
                  setLastNameHasError={setRepresentativeLastNameHasError}
                  setPhoneHasError={setRepresentativePhoneHasError}
                  setTitleHasError={setRepresentativeTitleHasError}
                  setWebsiteHasError={setWebsiteHasError}
                />
              ))}

              {loggedUser.role === "SUPER_ADMIN" && (
                <Row className="mt-5">
                  <Col>
                    {editMode ? (
                      <>
                        <Button
                          variant="outline-danger mr-4"
                          disabled={!client?.id}
                          onClick={() => setShowArchiveModal(true)}
                        >
                          Archive Client
                        </Button>
                        <Button
                          variant="outline-info"
                          disabled={!client?.id}
                          onClick={() =>
                            addExtraRepresentative({
                              id: Math.random(),
                              clientId: client?.id,
                              isNew: true,
                            })
                          }
                        >
                          Add Representative
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="info mr-4"
                          onClick={() => setEditMode(true)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="secondary"
                          disabled={!client?.id}
                          onClick={onCopy}
                        >
                          Duplicate
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              )}
            </Col>

            <Col
              xs={12}
              lg={5}
              className={classnames("d-flex flex-column", classes.notesColumn)}
            >
              <h3 className="text-dark">Notes</h3>
              <FormGroup
                className={classnames(
                  "mt-4 flex-grow-1 pb-3 w-100",
                  classes.infoInput
                )}
              >
                <FormLabel className={classes.label}>
                  About the client
                </FormLabel>
                {editMode ? (
                  <FormControl
                    className={classnames(classes.textarea, "border-dark")}
                    as="textarea"
                    value={notes || ""}
                    onChange={(event: any) => setNotes(event.target.value)}
                    placeholder="Write your notes here"
                  />
                ) : (
                  <>
                    <br />
                    <span className={classes.infoValue}>{notes || "-"}</span>
                  </>
                )}
              </FormGroup>
            </Col>
          </Row>
        </div>
      </ModalWithIcon>

      {showArchiveModal && (
        <ConfirmActionModal
          show={showArchiveModal}
          onHide={() => setShowArchiveModal(false)}
          title={`Are you sure you want to archive "${client.title}"?`}
          description="The client and their projects will no longer be available."
          onConfirmPress={onArchive}
          confirmText="Yes, archive this client"
          cancelText="Don't archive"
        />
      )}
    </>
  );
};

export default CreateOrEditClientModal;
