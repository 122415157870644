/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { data } from "jquery";

interface InitialState {
  data: Array<any>;
  loading: boolean;
  error: Error | null;
  nameAlreadyExists: boolean;
  superUsersToSelect: any[] | null;
  superUserClient: any;
  superUserClientError: Error | null;
  dataDeleted: boolean;
  deletingData: boolean;
}

export default createSlice({
  name: "clients",
  initialState: {
    data: [],
    loading: true,
    error: null,
    nameAlreadyExists: false,
    superUsersToSelect: null,
    superUserClient: null,
    superUserClientError: null,
    dataDeleted: false,
    deletingData: false,
  } as InitialState,
  reducers: {
    fetch: (state) => state,

    addError: (state, action) => ({
      ...state,
      error: action.payload,
    }),

    addAll: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      data: action.payload,
    }),

    addSingle: (state, action) => ({
      ...state,
      data: [action.payload, ...state.data],
    }),

    createFetch: (state, action) => state,

    updateFetch: (state, action) => state,

    update: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      const clientToUpdate = newState.data.find(
        (client: any) => client.id === action.payload.id
      );
      Object.assign(clientToUpdate, action.payload);
      return newState;
    },

    archiveFetch: (state, action) => state,

    remove: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.data = newState.data.filter(
        (client: any) => client.id !== action.payload
      );
      return newState;
    },

    copyFetch: (state, action) => state,

    setNameAlreadyExists: (state, action) => ({
      ...state,
      nameAlreadyExists: action.payload,
    }),

    superUsersToSelectFetch: (state) => state,

    addSuperUsersToSelect: (state, action) => ({
      ...state,
      superUsersToSelect: action.payload,
    }),

    addSuperUserClient: (state, action) => ({
      ...state,
      superUserClient: action.payload,
    }),

    addSuperUserClientError: (state, action) => ({
      ...state,
      superUserClientError: action.payload,
    }),

    deleteAllDataFetch: (state) => ({
      ...state,
      deletingData: true,
    }),

    deleteAllDataSuccess: (state) => ({
      ...state,
      dataDeleted: true,
      deletingData: false,
    }),
  },
});
