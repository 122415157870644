import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ErrorTooltip } from "@ko2-consulting/leaf";
import PasswordValidator from "password-validator";
import { roles } from "../utils";
import usersSlice from "../redux/users/slice";
import { RootState } from "../redux/reducer";

const passwordValidatorSchema = new PasswordValidator();
passwordValidatorSchema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .not()
  .spaces();

const useStyles = createUseStyles({
  body: {
    padding: "1rem 4.5rem 4.5rem 4.5rem",
  },
  input: {
    backgroundImage: "url('') !important",
  },
  errorTooltip: {
    top: "2.2rem",
    right: "0.9rem !important",
  },
});

interface Props {
  onHide: any;
}

const CreateUserModal = ({ onHide }: Props) => {
  const classes = useStyles();
  const [formValidated, setFormValidated] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [invalidPassword, setInvalidPassword] = useState<boolean>(false);
  const dispatch: Dispatch = useDispatch();
  const isLoading: boolean = useSelector<RootState, boolean>(
    (state) => state.users.isLoading
  );
  const users: any[] = useSelector<RootState, any>((state) => state.users.data);
  const usernameAlreadyExists: boolean = users.some(
    (user: any) => user.username === username
  );
  const emailAlreadyExists: boolean = users.some(
    (user: any) => user.email === email
  );

  useEffect(() => {
    if (selectedRole && email && username && password && !isLoading) {
      onHide();
    }
  }, [isLoading]);

  useEffect(() => {
    if (invalidPassword && passwordValidatorSchema.validate(password)) {
      setInvalidPassword(false);
    }
  }, [password]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    const isInvalidPassword: boolean =
      !passwordValidatorSchema.validate(password);

    if (form.checkValidity() === false || isInvalidPassword) {
      event.stopPropagation();
      setFormValidated(true);
      if (isInvalidPassword) {
        setInvalidPassword(true);
      }
      return;
    }

    dispatch(
      usersSlice.actions.createUserFetch({
        username: username.trim(),
        password: password.trim(),
        email: email.trim(),
        auth0Role: selectedRole,
      })
    );

    setFormValidated(true);
  };

  return (
    <Modal show onHide={onHide} centered size="lg" backdrop="static">
      <Modal.Header className="border-0" closeButton />
      <Modal.Body
        className={classnames(
          "d-flex flex-column justify-content-center align-items-center text-justify",
          classes.body
        )}
      >
        <h2 className="mb-5">New User</h2>

        <Form
          noValidate
          className="w-75"
          onSubmit={handleSubmit}
          validated={formValidated}
        >
          <Form.Group as={Row}>
            <Form.Label column xs={3}>
              Role
            </Form.Label>
            <Col xs={9}>
              <Form.Control
                className={classes.input}
                as="select"
                value={selectedRole}
                onChange={(event: any) => setSelectedRole(event.target.value)}
                required
              >
                <option disabled value="">
                  Select Role
                </option>
                {Object.keys(roles).map((roleId: string) => (
                  <option key={roleId} value={roleId}>
                    {roles[roleId]}
                  </option>
                ))}
              </Form.Control>
              <ErrorTooltip
                className={classes.errorTooltip}
                message="Please select a role."
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mt-4">
            <Form.Label column xs={3}>
              Email
            </Form.Label>
            <Col xs={9}>
              <Form.Control
                type="email"
                className={classes.input}
                value={email}
                onChange={(event: any) => setEmail(event.target.value)}
                required
                isInvalid={emailAlreadyExists}
              />
              <ErrorTooltip
                className={classes.errorTooltip}
                message={
                  emailAlreadyExists
                    ? "That email already exists."
                    : "Please enter a valid email."
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mt-4">
            <Form.Label column xs={3}>
              Username
            </Form.Label>
            <Col xs={9}>
              <Form.Control
                className={classes.input}
                value={username}
                onChange={(event: any) => setUsername(event.target.value)}
                isInvalid={usernameAlreadyExists}
                pattern="[a-zA-Z0-9]{4,20}"
                required
              />
              <ErrorTooltip
                className={classes.errorTooltip}
                message={
                  usernameAlreadyExists
                    ? "That username already exists."
                    : "Please enter a valid username."
                }
              />
              <Form.Text muted>
                4 to 20 characters (letters or numbers, no spaces).
              </Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mt-4">
            <Form.Label column xs={3}>
              Password
            </Form.Label>
            <Col xs={9}>
              <Form.Control
                className={classes.input}
                type="password"
                value={password}
                onChange={(event: any) => setPassword(event.target.value)}
                required
                isInvalid={invalidPassword}
              />
              <ErrorTooltip
                className={classes.errorTooltip}
                message="Please enter a valid password."
              />
              <Form.Text muted>
                Include at least one lowercase, uppercase and number. 8
                characters long or more.
              </Form.Text>
            </Col>
          </Form.Group>

          <Row className="w-100">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center mt-5"
            >
              <Button
                className="ml-2 mr-2"
                variant="outline-secondary"
                onClick={onHide}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="ml-2 mr-2"
                variant="outline-primary"
              >
                {isLoading && (
                  <Spinner
                    animation="grow"
                    variant="warning"
                    size="sm"
                    className="mr-1"
                  />
                )}
                Create User
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserModal;
