import classnames from "classnames";
import Col from "react-bootstrap/Col";
import FormLabel from "react-bootstrap/FormLabel";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import { createUseStyles } from "react-jss";
import { DeleteRowButton, ErrorTooltip } from "@ko2-consulting/leaf";
import FormControl from "react-bootstrap/FormControl";
import FormText from "react-bootstrap/FormText";
import Badge from "react-bootstrap/Badge";

const INFO_VALUE_MAX_LENGTH: number = 255;

interface Props {
  editMode: boolean;
  representative: any;
  superUsersToSelect: any[] | null;
  filteredSuperUsersToSelect: any[] | null;
  updateExtraRepresentative: Function;
  setTitleHasError: Function;
  setWebsiteHasError: Function;
  setFirstNameHasError: Function;
  setLastNameHasError: Function;
  setEmailHasError: Function;
  setPhoneHasError: Function;
}

const useStyles = createUseStyles({
  representativeContainer: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#979797",
    borderRadius: "20px",
    padding: "0.8rem",
  },
  infoInput: {
    "& input": {
      backgroundImage: 'url("") !important',
    },
  },
  label: {
    fontWeight: "500",
  },
  infoValue: {
    fontWeight: "100",
    fontSize: "1.1rem",
  },
  link: {
    color: "inherit",
    textDecoration: "underline",
    "&:hover": {
      color: "inherit",
    },
  },
  disabledLink: {
    cursor: "default",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
});

const normalizeWebsite = (website: string): string => {
  if (!website.includes("http://") || !website.includes("https://")) {
    return `http://${website}`;
  }
  return website;
};

const RepresentativeCard = ({
  editMode,
  representative,
  superUsersToSelect,
  filteredSuperUsersToSelect,
  updateExtraRepresentative,
  setEmailHasError,
  setFirstNameHasError,
  setLastNameHasError,
  setPhoneHasError,
  setTitleHasError,
  setWebsiteHasError,
}: Props) => {
  const classes = useStyles();

  const infoInvalidErrorMessage = (value: string): string => {
    return `Please shorten - ${value?.length}/${INFO_VALUE_MAX_LENGTH}`;
  };

  const superUsernameToShow = (): string => {
    if (
      !representative.superUserId ||
      representative.superUserId === "select"
    ) {
      return "-";
    }
    return (
      representative.superUser?.username ||
      superUsersToSelect?.find((su) => su.id === representative.superUserId)
        .username ||
      "-"
    );
  };

  return (
    <Row className={classnames(classes.representativeContainer, "mt-5")}>
      <Col xs={12}>
        <FormGroup className={classnames("mt-4", classes.infoInput)}>
          {editMode ? (
            <>
              <FormLabel className={classes.label}>User</FormLabel>
              <div className="position-relative">
                <DeleteRowButton
                  onClick={() =>
                    updateExtraRepresentative({
                      ...representative,
                      superUserId: "select",
                    })
                  }
                />
                <FormControl
                  className="border-dark"
                  custom
                  as="select"
                  onChange={(event: any) =>
                    updateExtraRepresentative({
                      ...representative,
                      superUserId: event.target.value,
                    })
                  }
                  value={representative.superUserId || "select"}
                >
                  <option disabled value="select">
                    Select an existing SuperUser
                  </option>
                  {representative?.superUser && (
                    <option value={representative.superUser.id}>
                      {representative.superUser.username}
                    </option>
                  )}
                  {!!representative.superUserId &&
                    representative.superUserId !== "select" && (
                      <option
                        key={representative.superUserId}
                        value={representative.superUserId}
                      >
                        {
                          superUsersToSelect?.find(
                            (user) => user.id === representative.superUserId
                          )?.username
                        }
                      </option>
                    )}
                  {filteredSuperUsersToSelect &&
                    filteredSuperUsersToSelect.map((superUser: any) => (
                      <option key={superUser.id} value={superUser.id}>
                        {superUser.username}
                      </option>
                    ))}
                </FormControl>
              </div>
              <ErrorTooltip
                message={infoInvalidErrorMessage(representative.website)}
              />
              <FormText muted className="mt-3">
                Select an existing SuperUser, not currently assigned to another
                client. Or create a new one &nbsp;
                <a href="/users" target="_blank" className={classes.link}>
                  here
                </a>
                .
              </FormText>
            </>
          ) : (
            <Badge
              className={classnames(classes.infoValue, "p-2")}
              variant="light"
            >
              {superUsernameToShow()}
            </Badge>
          )}
        </FormGroup>
      </Col>

      <Col xs={12} lg={editMode ? 6 : 12}>
        <FormGroup
          className={classnames(editMode ? "mt-4" : null, classes.infoInput)}
        >
          {editMode ? (
            <>
              <FormLabel className={classes.label}>Name</FormLabel>
              <FormControl
                className="border-dark"
                value={representative.firstName || ""}
                onChange={(event: any) => {
                  const { value } = event.target;
                  setFirstNameHasError(value.length > INFO_VALUE_MAX_LENGTH);
                  updateExtraRepresentative({
                    ...representative,
                    firstName: value,
                  });
                }}
                placeholder="Name"
              />
              <ErrorTooltip
                message={infoInvalidErrorMessage(representative.firsName)}
              />
            </>
          ) : (
            <span className={classes.infoValue}>
              {!representative.firstName && !representative.lastName
                ? "-"
                : `${representative.firstName || ""} ${
                    representative.lastName || ""
                  }`}
            </span>
          )}
        </FormGroup>
      </Col>

      {editMode && (
        <Col xs={12} lg={6}>
          <FormGroup
            className={classnames(editMode ? "mt-4" : null, classes.infoInput)}
          >
            <FormLabel className={classes.label}>Last Name</FormLabel>
            <FormControl
              className="border-dark"
              value={representative.lastName || ""}
              onChange={(event: any) => {
                const { value } = event.target;
                setLastNameHasError(value.length > INFO_VALUE_MAX_LENGTH);
                updateExtraRepresentative({
                  ...representative,
                  lastName: value,
                });
              }}
              placeholder="Last Name"
            />
            <ErrorTooltip
              message={infoInvalidErrorMessage(representative.lastName)}
            />
          </FormGroup>
        </Col>
      )}

      <Col xs={12} lg={editMode ? 6 : 12}>
        <FormGroup
          className={classnames(editMode ? "mt-4" : null, classes.infoInput)}
        >
          {editMode ? (
            <>
              <FormLabel className={classes.label}>Title</FormLabel>
              <FormControl
                className="border-dark"
                value={representative.title || ""}
                onChange={(event: any) => {
                  const { value } = event.target;
                  setTitleHasError(value.length > INFO_VALUE_MAX_LENGTH);
                  updateExtraRepresentative({
                    ...representative,
                    title: value,
                  });
                }}
                placeholder="Title"
              />
              <ErrorTooltip
                message={infoInvalidErrorMessage(representative.title)}
              />
            </>
          ) : (
            <span className={classes.infoValue}>
              {representative.title || "-"}
            </span>
          )}
        </FormGroup>
      </Col>

      <Col xs={12} lg={editMode ? 6 : 12}>
        <FormGroup
          className={classnames(editMode ? "mt-4" : null, classes.infoInput)}
        >
          {editMode ? (
            <>
              <FormLabel className={classes.label}>Website</FormLabel>
              <FormControl
                className="border-dark"
                value={representative.website || ""}
                onChange={(event: any) => {
                  const { value } = event.target;
                  setWebsiteHasError(value.length > INFO_VALUE_MAX_LENGTH);
                  updateExtraRepresentative({
                    ...representative,
                    website: value,
                  });
                }}
                placeholder="URL"
              />
              <ErrorTooltip
                message={infoInvalidErrorMessage(representative.website)}
              />
            </>
          ) : (
            <a
              href={
                representative.website
                  ? normalizeWebsite(representative.website)
                  : "#"
              }
              className={classnames(
                classes.link,
                !representative.website ? classes.disabledLink : null
              )}
              target={representative.website ? "_blank" : "_self"}
              rel="noreferrer"
            >
              <span className={classes.infoValue}>
                {representative.website || "-"}
              </span>
            </a>
          )}
        </FormGroup>
      </Col>

      <Col xs={12} lg={editMode ? 6 : 12}>
        <FormGroup
          className={classnames(editMode ? "mt-4" : null, classes.infoInput)}
        >
          {editMode ? (
            <>
              <FormLabel className={classes.label}>Email</FormLabel>
              <FormControl
                className="border-dark"
                value={representative.email || ""}
                onChange={(event: any) => {
                  const { value } = event.target;
                  setEmailHasError(value.length > INFO_VALUE_MAX_LENGTH);
                  updateExtraRepresentative({
                    ...representative,
                    email: value,
                  });
                }}
                placeholder="Email"
              />
              <ErrorTooltip
                message={infoInvalidErrorMessage(representative.email)}
              />
            </>
          ) : (
            <span className={classes.infoValue}>
              {representative.email || "-"}
            </span>
          )}
        </FormGroup>
      </Col>

      <Col xs={12} lg={editMode ? 6 : 12}>
        <FormGroup
          className={classnames(editMode ? "mt-4" : null, classes.infoInput)}
        >
          {editMode ? (
            <>
              <FormLabel className={classes.label}>Phone</FormLabel>
              <FormControl
                className="border-dark"
                value={representative.phone || ""}
                onChange={(event: any) => {
                  const { value } = event.target;
                  setPhoneHasError(value.length > INFO_VALUE_MAX_LENGTH);
                  updateExtraRepresentative({
                    ...representative,
                    phone: value,
                  });
                }}
                placeholder="Phone"
              />
              <ErrorTooltip
                message={infoInvalidErrorMessage(representative.phone)}
              />
            </>
          ) : (
            <span className={classes.infoValue}>
              {representative.phone || "-"}
            </span>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

export default RepresentativeCard;
