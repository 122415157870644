import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import store from "../redux/store";

const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const configWithToken: AxiosRequestConfig = { ...config };
  configWithToken.headers = {
    Authorization: `Bearer ${store.getState().loggedUser.accessToken}`,
  };
  return configWithToken;
});

export default httpClient;
