import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../redux/reducer";
import usersSlice from "../redux/users/slice";

const useBackofficeUsers = () => {
  const users = useSelector<RootState, any>((state) => state.users);
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (users.data.length === 0) {
      dispatch(usersSlice.actions.backofficeUsersFetch());
    }
  }, []);

  return users;
};

export default useBackofficeUsers;
