import { useEffect, useRef, useState, useMemo } from "react";
import { createUseStyles } from "react-jss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import FormCheck from "react-bootstrap/FormCheck";
import Spinner from "react-bootstrap/Spinner";
import Collapse from "react-bootstrap/Collapse";
import {
  ChevronDoubleDown,
  ChevronDoubleUp,
  Pencil,
  Folder2,
} from "react-bootstrap-icons";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import dayjs from "dayjs";
import {
  ModalWithIcon,
  EditableText,
  ErrorTooltip,
  ConfirmActionModal,
  SectionsList,
  NoData,
  StringUtils,
} from "@ko2-consulting/leaf";
import { RootState } from "../redux/reducer";
import projectsSlice from "../redux/projects/slice";
import QuestionnairesTable from "./QuestionnairesTable";
import DateInput from "./DateInput";
import { useSections, useCanEditForm } from "../hooks";

const BACK_YEAR_OPTIONS: number[] = [-1, -2, -3, -4, -5, -6, -7, -8, -9];

const useStyles = createUseStyles({
  editableGreyText: {
    textAlign: "justify",
    marginBottom: "0.5rem",
  },
  iconWithLetters: {
    color: "#000000",
    textTransform: "uppercase",
  },
  clientName: {
    textTransform: "uppercase",
    fontSize: "1.1rem",
  },
  title: {
    fontWeight: "500",
  },
  dropdownsFirstRowItem: {
    width: "49%",
  },
  dropdownsSecondRowItem: {
    maxWidth: "30%",
  },
  dropdown: {
    background: 'url("") !important',
  },
  statusErrorTooltip: {
    top: "4.5rem !important",
    right: "0.9rem !important",
  },
  reportingYearErrorTooltip: {
    top: "4.5rem !important",
    right: "22.7rem !important",
  },
  clientErrorTooltip: {
    top: "4.5rem",
    right: "22.75rem",
  },
  "@media (max-width: 992px)": {
    clientErrorTooltip: {
      right: "15.75rem",
    },
  },
  textarea: {
    resize: "none",
    height: "10rem !important",
  },
  notes: {
    fontWeight: "200",
    fontSize: "1.1rem",
    marginLeft: "3px",
  },
  check: {
    marginBottom: "0.35rem",
  },
  collapseHeader: {
    cursor: "pointer",
    fontWeight: 400,
    borderWidth: "1px",
    borderRadius: "0.4rem",
    color: "#FFFFFF",
    "& h3": {
      color: "#FFFFFF",
    },
  },
  exportButton: {
    width: "5rem",
  },
  downloadFilesButton: {
    width: "12rem",
  },
  modalBody: {
    wordWrap: "break-word",
  },
});

interface Props {
  show: boolean;
  onHide: Function;
  projectId: number | null;
  projects: Array<any>;
}

const CreateOrEditProjectModal = ({
  show,
  onHide,
  projectId,
  projects,
}: Props) => {
  const createdProjectId: number | null = useSelector<RootState, any>(
    (state) => state.projects.createdProjectId
  );
  const project = projects.find(
    (p: any) => p.id === (projectId || createdProjectId)
  );
  const nameRef = useRef<string>(project?.name || "new-project");
  const titleRef = useRef<string>(project?.title || "New Project");
  const descriptionRef = useRef<string>(
    project && project.description !== undefined && project.description !== null
      ? project.description
      : ""
  );
  const [editMode, setEditMode] = useState<boolean>(!project?.id);
  const [clientId, setClientId] = useState<string>(project?.client?.id);
  const [status, setStatus] = useState<string>(project?.status);
  const [dueDate, setDueDate] = useState<string | undefined>(
    project?.dueDate
      ? dayjs(project.dueDate, "YYYY-MM-DD").format("MM/DD/YYYY")
      : undefined
  );

  const [reportingYear, setReportingYear] = useState<string>(
    project?.reportingYear || "select"
  );
  const reportingYearOptions = useMemo(() => {
    const options: string[] = [];
    const actualYear: number = dayjs().year();
    for (let i = -10; i <= 20; i += 1) {
      options.push((actualYear + i).toString());
    }
    return options;
  }, []);

  const [backYears, setBackYears] = useState<number | string>(
    project?.backYears || "select"
  );
  const [questionnaireLoginRequired, setQuestionnaireLoginRequired] =
    useState<boolean>(project?.questionnaireLoginRequired);
  const [notes, setNotes] = useState<string>(project?.notes);
  const [nameHasError, setNameHasError] = useState<boolean>(false);
  const [titleHasError, setTitleHasError] = useState<boolean>(false);
  const [descriptionHasError, setDescriptionHasError] =
    useState<boolean>(false);
  const [clientIdHasError, setClientIdHasError] = useState<boolean>(false);
  const [statusHasError, setStatusHasError] = useState<boolean>(false);
  const [reportingYearHasError, setReportingYearHasError] =
    useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [questionnaireIdToRevertSubmit, setQuestionnaireIdToRevertSubmit] =
    useState<number | undefined>(undefined);

  const [questionnairesToCreate, setQuestionnairesToCreate] = useState<
    Array<any>
  >([]);
  const [questionnairesToUpdate, setQuestionnairesToUpdate] = useState<
    Array<any>
  >([]);
  const [questionnairesIdsWithError, setQuestionnairesIdsWithError] = useState<
    number[]
  >([]);
  const [formsCollapsed, setFormsCollapsed] = useState<boolean>(true);
  const [questionsCollapsed, setQuestionsCollapsed] = useState<boolean>(false);

  const classes = useStyles();
  const dispatch: Dispatch = useDispatch();
  const clients = useSelector<RootState, any>((state) => state.clients);
  const nameAlreadyExists = useSelector<RootState, boolean>(
    (state) => state.projects.nameAlreadyExists
  );
  const generatingExport = useSelector<RootState, boolean>(
    (state) => state.projects.generatingExport
  );
  const downloadingFiles = useSelector<RootState, boolean>(
    (state) => state.projects.downloadingFiles
  );
  const sections = useSections();

  /**
   * This state variable exists to force a re-render on input change, due a problem with
   * react-editable-content and React hooks.
   */
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState<string>();

  // Status permissions
  const canEditForm: boolean = useCanEditForm(status);

  const questionnairesTableRef = useRef<any>();

  useEffect(() => {
    if (nameAlreadyExists) {
      dispatch(projectsSlice.actions.setNameAlreadyExists(false));
    }
  }, [editMode]);

  const handleOnHide = (isCancel: boolean = false) => {
    if (editMode && project) {
      if (isCancel) {
        questionnairesTableRef.current?.reset();
      }
      nameRef.current = isCancel
        ? project.name
        : StringUtils.removeHTMLCharacters(nameRef.current);
      titleRef.current = isCancel
        ? project.title
        : StringUtils.removeHTMLCharacters(titleRef.current);
      descriptionRef.current = isCancel
        ? project.description
        : StringUtils.removeHTMLCharacters(descriptionRef.current);
      setEditMode(false);
      setQuestionnairesToCreate([]);
      setQuestionnairesToUpdate([]);
      dispatch(
        projectsSlice.actions.removeAllQuestionnaireIdsWithExistingName()
      );
    } else {
      onHide();
    }
  };

  const createOrUpdateQuestionnaires = () => {
    if (
      questionnairesToCreate.length > 0 ||
      questionnairesToUpdate.length > 0
    ) {
      [...questionnairesToCreate].forEach(
        (questionnaireToCreate: any, index: number) => {
          const isLast: boolean = index === questionnairesToCreate.length - 1;
          const questionnaireData: any = {
            id: questionnaireToCreate.id,
            name: StringUtils.removeHTMLCharacters(questionnaireToCreate.name),
            projectId,
            link: questionnaireToCreate.link,
          };

          if (questionnaireLoginRequired) {
            questionnaireData.user = {
              username: StringUtils.removeHTMLCharacters(
                questionnaireToCreate.name
              ),
              password: StringUtils.removeHTMLCharacters(
                questionnaireToCreate.password
              ),
            };
          }

          dispatch(
            projectsSlice.actions.createQuestionnaireFetch({
              questionnaireData,
              callback:
                isLast && questionnairesToUpdate.length === 0
                  ? handleOnHide
                  : null,
            })
          );
        }
      );

      [...questionnairesToUpdate].forEach(
        (questionnaireToUpdate: any, index: number) => {
          const isLast: boolean = index === questionnairesToUpdate.length - 1;
          const questionnaireData: any = {
            id: questionnaireToUpdate.id,
            name: StringUtils.removeHTMLCharacters(questionnaireToUpdate.name),
            projectId,
            link: questionnaireToUpdate.link,
          };

          if (questionnaireLoginRequired) {
            questionnaireData.user = {
              id: questionnaireToUpdate.userId,
              username: StringUtils.removeHTMLCharacters(
                questionnaireToUpdate.name
              ),
              password: StringUtils.removeHTMLCharacters(
                questionnaireToUpdate.password
              ),
            };
          }

          if (questionnaireToUpdate.archived) {
            questionnaireData.archived = true;
          }
          dispatch(
            projectsSlice.actions.updateQuestionnaireFetch({
              questionnaireData,
              callback: isLast ? handleOnHide : null,
            })
          );
        }
      );
    } else {
      handleOnHide();
    }
  };

  const onDonePress = () => {
    let dropdownsHaveError: boolean = false;
    if (!clientId) {
      setClientIdHasError(true);
      dropdownsHaveError = true;
    }
    if (!status) {
      setStatusHasError(true);
      dropdownsHaveError = true;
    }

    if (reportingYear === "select") {
      setReportingYearHasError(true);
      dropdownsHaveError = true;
    }

    if (dropdownsHaveError || questionnairesIdsWithError.length > 0) {
      return;
    }
    if (!nameHasError && !titleHasError && !descriptionHasError) {
      let projectData: any = {};

      const normalizedName: string = StringUtils.removeHTMLCharacters(
        nameRef.current
      );
      const normalizedTitle: string = StringUtils.removeHTMLCharacters(
        titleRef.current
      );
      const normalizedDescription: string = StringUtils.removeHTMLCharacters(
        descriptionRef.current
      );

      if (project?.id) {
        projectData.id = project.id;

        if (normalizedName !== project.name) {
          projectData.name = normalizedName;
        }
        if (normalizedTitle !== project.title) {
          projectData.title = normalizedTitle;
        }

        if (normalizedDescription !== project.description) {
          projectData.description = normalizedDescription;
        }

        if (status !== project.status) {
          projectData.status = status;
        }

        if (clientId !== project.clientId) {
          projectData.clientId = clientId;
        }

        const formattedDueDate = dayjs(dueDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        if (formattedDueDate !== dayjs(project.dueDate).format("YYYY-MM-DD")) {
          projectData.dueDate = formattedDueDate;
        }

        if (notes !== project.notes) {
          projectData.notes = notes;
        }

        if (questionnaireLoginRequired !== project.questionnaireLoginRequired) {
          projectData.questionnaireLoginRequired = questionnaireLoginRequired;
        }

        if (reportingYear !== project.reportingYear) {
          projectData.reportingYear = reportingYear;
        }

        if (
          (backYears === "select" ? null : reportingYear) !== project.backYears
        ) {
          projectData.backYears = backYears === "select" ? null : backYears;
        }

        if (Object.keys(projectData).length > 1) {
          dispatch(projectsSlice.actions.updateFetch(projectData));
        }
        createOrUpdateQuestionnaires();
      } else {
        projectData = {
          name: normalizedName,
          title: normalizedTitle,
          description: normalizedDescription,
          status,
          clientId,
          dueDate: dueDate
            ? dayjs(dueDate, "MM/DD/YYYY").format("YYYY-MM-DD")
            : null,
          notes,
          questionnaireLoginRequired,
          reportingYear,
          backYears: backYears === "select" ? null : backYears,
        };
        dispatch(projectsSlice.actions.createFetch(projectData));

        createOrUpdateQuestionnaires();
      }
    }
  };

  const onArchive = () => {
    dispatch(projectsSlice.actions.archiveFetch(project.id));
    setShowArchiveModal(false);
    onHide();
  };

  const onCopy = () => {
    dispatch(projectsSlice.actions.copyFetch(project.id));
    onHide();
  };

  const onRemoveSectionFromProjectPress = (sectionId: number) =>
    dispatch(
      projectsSlice.actions.removeSectionFetch({
        id: projectId,
        sectionId,
      })
    );

  const onSectionSelectedFromDropdown = (event: any) => {
    dispatch(
      projectsSlice.actions.addSectionFetch({
        id: projectId,
        sectionId: event.target.value,
      })
    );
  };

  const onExportButtonClick = () =>
    dispatch(
      projectsSlice.actions.generateExportFetch({
        id: projectId,
        name: project.name,
      })
    );

  const onDownloadFilesButtonClick = () =>
    dispatch(
      projectsSlice.actions.downloadFilesFetch({
        id: projectId,
      })
    );

  const revertQuestionnaireSubmit = () => {
    dispatch(
      projectsSlice.actions.updateQuestionnaireFetch({
        questionnaireData: {
          id: questionnaireIdToRevertSubmit,
          submitState: "COMPLETED",
          submitDate: null,
          projectId,
        },
      })
    );
    setQuestionnaireIdToRevertSubmit(undefined);
  };

  const FormsCollapseIcon = formsCollapsed
    ? ChevronDoubleDown
    : ChevronDoubleUp;

  const QuestionsCollapseIcon = questionsCollapsed
    ? ChevronDoubleDown
    : ChevronDoubleUp;

  const sectionsToShow =
    project?.projectSections
      .map(({ sectionId }: any) =>
        sections.data.find(({ id }: any) => sectionId === id)
      )
      .filter((section: any) => section !== undefined) || [];

  const ModalHeaderIcon = editMode ? Pencil : Folder2;

  return (
    <>
      <ModalWithIcon
        show={
          showArchiveModal || questionnaireIdToRevertSubmit !== undefined
            ? false
            : show
        }
        onHide={() => handleOnHide(true)}
        onDonePress={onDonePress}
        icon={<ModalHeaderIcon color="#FFFFFF" size="1.4rem" />}
        title={`Editing "${project?.name || "new-project"}" settings`}
        headerEnabled={editMode}
      >
        <div
          className={classnames(
            "d-flex flex-column align-items-center ml-4 mr-4 mb-4 flex-wrap",
            classes.modalBody,
            !editMode ? "mt-2" : null
          )}
        >
          <Row className="w-100">
            <Col
              xs={12}
              className={classnames(
                "d-flex justify-content-end align-items-center text-dark",
                classes.editableGreyText
              )}
            >
              {editMode ? (
                <EditableText
                  textRef={nameRef}
                  maxLength={40}
                  onChange={(value: string) => {
                    nameRef.current = StringUtils.normalizeName(value);
                  }}
                  onError={(errorMessage: string) =>
                    setNameHasError(!!errorMessage)
                  }
                  customErrorMessage={
                    nameAlreadyExists ? "That name already exists." : undefined
                  }
                />
              ) : (
                nameRef.current
              )}
            </Col>
          </Row>

          {editMode ? (
            <>
              <Row className="w-100 d-flex align-items-center">
                <Col xs={12} lg={6}>
                  <span
                    className={classnames(classes.title, "mb-2 form-label")}
                  >
                    Project title
                  </span>
                  <h2>
                    <EditableText
                      textRef={titleRef}
                      onChange={(value: string) => {
                        titleRef.current = value;
                        nameRef.current = StringUtils.normalizeName(value);
                        setName(nameRef.current);
                      }}
                      maxLength={80}
                      onError={(errorMessage: string) =>
                        setTitleHasError(!!errorMessage)
                      }
                    />
                  </h2>
                </Col>
                <Col
                  xs={12}
                  lg={6}
                  className="d-flex align-items-center justify-content-between flex-wrap"
                >
                  <FormGroup className={classes.dropdownsFirstRowItem}>
                    <FormLabel className={classes.title}>
                      Project Status
                    </FormLabel>
                    <FormControl
                      className={classnames(
                        "border-dark",
                        statusHasError ? classes.dropdown : ""
                      )}
                      as="select"
                      custom
                      defaultValue={status || "Select"}
                      onChange={(event: any) => {
                        setStatus(event.target.value);
                        setStatusHasError(false);
                      }}
                      isInvalid={statusHasError}
                    >
                      <option disabled value="Select">
                        Set Status
                      </option>
                      <option value="PLANNING">Planning</option>
                      <option value="ACTIVE">Active</option>
                      <option value="ANALYZING">Analyzing</option>
                      <option value="COMPLETED">Completed</option>
                    </FormControl>
                    <ErrorTooltip
                      className={classes.statusErrorTooltip}
                      message="Please select a status"
                    />
                  </FormGroup>

                  <FormGroup className={classes.dropdownsFirstRowItem}>
                    <FormLabel className={classes.title}>Client</FormLabel>
                    <FormControl
                      className={classnames(
                        "border-dark",
                        clientIdHasError ? classes.dropdown : ""
                      )}
                      as="select"
                      custom
                      defaultValue={clientId || "Select"}
                      onChange={(event: any) => {
                        setClientId(event.target.value);
                        setClientIdHasError(false);
                      }}
                      isInvalid={clientIdHasError}
                    >
                      <option disabled value="Select">
                        Select Client
                      </option>
                      {clients.data.map((client: any) => (
                        <option key={client.id} value={client.id}>
                          {client.title}
                        </option>
                      ))}
                    </FormControl>
                    <ErrorTooltip
                      className={classes.clientErrorTooltip}
                      message="Please select a client"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="w-100 d-flex align-items-center">
                <Col xs={12} lg={6}>
                  <span
                    className={classnames(classes.title, "mb-2 form-label")}
                  >
                    Description
                  </span>
                  <EditableText
                    className={classnames(
                      classes.editableGreyText,
                      "text-dark"
                    )}
                    textRef={descriptionRef}
                    onChange={(value: string) => {
                      descriptionRef.current = value;
                    }}
                    maxLength={80}
                    onError={(errorMessage: string) =>
                      setDescriptionHasError(!!errorMessage)
                    }
                    required={false}
                    placeholder="Project Description"
                  />
                </Col>
                <Col
                  xs={12}
                  lg={6}
                  className="d-flex justify-content-between align-items-center flex-wrap"
                >
                  <FormGroup className={classes.dropdownsSecondRowItem}>
                    <FormLabel className={classes.title}>
                      Reporting Year
                    </FormLabel>
                    <FormControl
                      as="select"
                      className={classnames(
                        "border-dark",
                        reportingYearHasError ? classes.dropdown : ""
                      )}
                      custom
                      value={reportingYear}
                      onChange={(event: any) => {
                        setReportingYear(event.target.value);
                        setReportingYearHasError(false);
                      }}
                      isInvalid={reportingYearHasError}
                    >
                      <option disabled value="select">
                        Select Year
                      </option>
                      {reportingYearOptions.map((year: string) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </FormControl>
                    <ErrorTooltip
                      className={classes.reportingYearErrorTooltip}
                      message="Please select a year"
                    />
                  </FormGroup>

                  <FormGroup className={classes.dropdownsSecondRowItem}>
                    <FormLabel className={classes.title}>Back Years</FormLabel>
                    <FormControl
                      className="border-dark"
                      disabled={!reportingYear}
                      as="select"
                      custom
                      value={backYears}
                      onChange={(event: any) =>
                        setBackYears(event.target.value)
                      }
                    >
                      <option disabled value="select">
                        Select
                      </option>
                      {BACK_YEAR_OPTIONS.map((backYear: number) => (
                        <option key={backYear} value={backYear}>
                          {backYear}
                        </option>
                      ))}
                    </FormControl>
                  </FormGroup>

                  <FormGroup className={classes.dropdownsSecondRowItem}>
                    <FormLabel className={classes.title}>Due Date</FormLabel>
                    <DateInput
                      id="due-date"
                      value={dueDate}
                      onChange={setDueDate}
                      placeholder="MM/DD/YYYY"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="w-100">
                <Col
                  xs={12}
                  className={classnames(
                    "d-flex justify-content-center align-items-center text-dark",
                    classes.clientName,
                    classes.editableGreyText
                  )}
                >
                  {project?.client.name}
                </Col>
              </Row>

              <Row className="w-100">
                <Col
                  xs={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <h1 className={classnames(classes.title, "text-center")}>
                    {titleRef.current}
                  </h1>
                </Col>
              </Row>

              <Row className="w-100">
                <Col
                  xs={12}
                  className={classnames(
                    "d-flex justify-content-center align-items-center mt-2 text-dark",
                    classes.editableGreyText
                  )}
                >
                  {descriptionRef.current || ""}
                </Col>
              </Row>

              <Row className="w-100">
                <Col
                  xs={12}
                  className="d-flex justify-content-center align-items-center mt-2 flex-column"
                >
                  <div>
                    Project Status
                    <strong>{` ${StringUtils.capitalize(
                      project?.status.toLowerCase()
                    )}`}</strong>
                  </div>
                  <div>
                    Project Year
                    <strong>{` ${project?.reportingYear}`}</strong>
                  </div>
                  <div>
                    Due Date
                    <strong>
                      {project?.dueDate
                        ? ` ${dayjs(project.dueDate).format("MM/DD/YYYY")}`
                        : "-"}
                    </strong>
                  </div>
                  <div>
                    Back Years
                    <strong>{` ${project?.backYears || "-"}`}</strong>
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row className="mt-5 w-100">
            <Col xs={12}>
              <div
                className={classnames(
                  "d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3 mb-4 bg-dark",
                  classes.collapseHeader
                )}
                onClick={() => {
                  setFormsCollapsed(!formsCollapsed);
                  setQuestionsCollapsed(true);
                }}
              >
                <h3 className="mb-0">Respondents</h3>
                <FormsCollapseIcon size="1.1rem" />
              </div>
              <Collapse in={!formsCollapsed} mountOnEnter>
                <div
                  className="mt-5"
                  style={{ paddingLeft: editMode ? "0.2rem" : "0.1rem" }}
                >
                  {canEditForm && editMode && (
                    <FormCheck
                      className={classnames(classes.check, "mb-4")}
                      id="questionnaire-login-required-switch"
                      type="switch"
                      label="Require ID and Password to access"
                      checked={questionnaireLoginRequired}
                      onChange={(event: any) =>
                        setQuestionnaireLoginRequired(event.target.checked)
                      }
                    />
                  )}

                  <QuestionnairesTable
                    questionnaires={project?.questionnaires}
                    editMode={canEditForm ? editMode : false}
                    questionnairesToCreate={questionnairesToCreate}
                    questionnairesToUpdate={questionnairesToUpdate}
                    onQuestionnairesToCreateChange={setQuestionnairesToCreate}
                    onQuestionnairesToUpdateChange={setQuestionnairesToUpdate}
                    projectId={project?.id}
                    client={clients.data.find(
                      (client: any) => client.id === clientId
                    )}
                    idsWithError={questionnairesIdsWithError}
                    onIdsWithErrorChange={setQuestionnairesIdsWithError}
                    passwordEnabled={questionnaireLoginRequired}
                    ref={questionnairesTableRef}
                    setQuestionnaireIdToRevertSubmit={
                      setQuestionnaireIdToRevertSubmit
                    }
                  />
                </div>
              </Collapse>
            </Col>
          </Row>

          {!editMode && (
            <Row className="w-100 mt-5">
              <Col xs={12}>
                <div
                  className={classnames(
                    classes.collapseHeader,
                    "d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3 mb-4 bg-dark"
                  )}
                  onClick={() => {
                    setQuestionsCollapsed(!questionsCollapsed);
                    setFormsCollapsed(true);
                  }}
                >
                  <h3 className="mb-0">Questionnaire</h3>
                  <QuestionsCollapseIcon size="1.1rem" />
                </div>
                <Collapse in={!questionsCollapsed} mountOnEnter>
                  <div className="mt-5">
                    {sections.loading ? (
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <Spinner animation="grow" variant="warning" />
                      </div>
                    ) : (
                      <>
                        {sectionsToShow.length === 0 && (
                          <NoData
                            className="mb-3"
                            title="This Project could use some Question Sets."
                            description="Add one by editing this Project."
                          />
                        )}

                        <SectionsList
                          sections={sectionsToShow}
                          bordered
                          displayMode="PROJECTS"
                          onRemoveFromProjectPress={
                            onRemoveSectionFromProjectPress
                          }
                          onSectionOrderChange={(
                            section: any,
                            direction: any
                          ) => {
                            dispatch(
                              projectsSlice.actions.changeSectionOrderFetch({
                                section,
                                direction,
                                project,
                              })
                            );
                          }}
                          isInModal
                          backYears={
                            backYears === "select"
                              ? undefined
                              : Number(backYears)
                          }
                          reportingYear={reportingYear}
                          cardToolbarDisabled={!canEditForm}
                        />

                        {canEditForm && (
                          <FormControl
                            className="border-dark mt-3"
                            as="select"
                            custom
                            value="select"
                            onChange={onSectionSelectedFromDropdown}
                          >
                            <option disabled value="select">
                              Add a Question Set to the Questionnaire
                            </option>
                            {sections.data
                              .filter(
                                (section: any) =>
                                  section.name !== "starters" &&
                                  !project?.projectSections?.some(
                                    ({ sectionId }: any) =>
                                      sectionId === section.id
                                  )
                              )
                              .map((section: any) => (
                                <option key={section.id} value={section.id}>
                                  {section.name}
                                </option>
                              ))}
                          </FormControl>
                        )}
                      </>
                    )}
                  </div>
                </Collapse>
              </Col>
            </Row>
          )}

          <Row className="w-100 mt-5 pl-1">
            <Col xs={12}>
              <h2 className="mb-4">Notes</h2>
              {editMode ? (
                <FormControl
                  className={classnames(classes.textarea, "border-dark")}
                  as="textarea"
                  value={notes || ""}
                  onChange={(event: any) => setNotes(event.target.value)}
                  placeholder="Write your notes here"
                />
              ) : (
                <span className={classes.notes}>{notes || "-"}</span>
              )}
            </Col>
          </Row>

          <Row className="w-100 mt-5 pl-1">
            <Col xs={12}>
              <h2>Actions</h2>
            </Col>
          </Row>
          <Row className="w-100 mt-3 pl-1">
            <Col xs={12}>
              {editMode ? (
                <Button
                  variant="danger"
                  disabled={!project?.id}
                  onClick={() => setShowArchiveModal(true)}
                >
                  Archive
                </Button>
              ) : (
                <>
                  <Button variant="info mr-4" onClick={() => setEditMode(true)}>
                    Edit Settings
                  </Button>
                  <Button
                    variant="secondary mr-4"
                    disabled={!project?.id}
                    onClick={onCopy}
                  >
                    Duplicate
                  </Button>
                  <Button
                    variant="secondary mr-4"
                    onClick={onExportButtonClick}
                    disabled={generatingExport}
                    className={classes.exportButton}
                  >
                    {generatingExport ? (
                      <Spinner animation="border" variant="dark" size="sm" />
                    ) : (
                      "Export"
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={onDownloadFilesButtonClick}
                    disabled={downloadingFiles}
                    className={classes.downloadFilesButton}
                  >
                    {downloadingFiles ? (
                      <Spinner animation="border" variant="dark" size="sm" />
                    ) : (
                      "Download Project Files"
                    )}
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </div>
      </ModalWithIcon>

      {showArchiveModal && (
        <ConfirmActionModal
          show={showArchiveModal}
          onHide={() => setShowArchiveModal(false)}
          title={`Are you sure you want to archive "${project.title}"?`}
          description="The project will no longer be available."
          onConfirmPress={onArchive}
          confirmText="Yes, archive this project"
          cancelText="Don't archive"
        />
      )}

      {!!questionnaireIdToRevertSubmit && (
        <ConfirmActionModal
          show
          onHide={() => setQuestionnaireIdToRevertSubmit(undefined)}
          title="Make this Respondant editable again?"
          description=""
          onConfirmPress={revertQuestionnaireSubmit}
          confirmText="Yes, make it editable"
          cancelText="No, keep it Submitted"
          confirmButtonVariant="outline-success"
        />
      )}
    </>
  );
};

export default CreateOrEditProjectModal;
