import { StrictMode } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import store from "./redux/store";
import App from "./App";
import "./utils/jQueryLoader";
import "./theme.scss";
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "text-security/text-security.css";

render(
  <StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as any}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as any}
      audience={process.env.REACT_APP_API_URL}
      useRefreshTokens
    >
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  </StrictMode>,
  document.getElementById("root")
);
