/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  data: Array<any>;
  loading: boolean;
  error: Error | null;
  nameAlreadyExists: boolean;
  questionnaireIdsWithExistingName: number[];
  createdProjectId: number | null;
  generatingExport: boolean;
  downloadingFiles: boolean;
}

export default createSlice({
  name: "projects",
  initialState: {
    data: [],
    loading: true,
    error: null,
    nameAlreadyExists: false,
    questionnaireIdsWithExistingName: [],
    createdProjectId: null,
    generatingExport: false,
    downloadingFiles: false,
  } as InitialState,
  reducers: {
    fetch: (state) => state,

    addError: (state, action) => ({
      ...state,
      error: action.payload,
    }),

    addAll: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      data: action.payload,
    }),

    addSingle: (state, action) => ({
      ...state,
      data: [action.payload, ...state.data],
    }),

    createFetch: (state, action) => state,

    updateFetch: (state, action) => state,

    update: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      const projectToUpdate = newState.data.find(
        (project: any) => project.id === action.payload.id
      );
      Object.assign(projectToUpdate, action.payload);
      return newState;
    },

    archiveFetch: (state, action) => state,

    remove: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.data = newState.data.filter(
        (project: any) => project.id !== action.payload
      );
      return newState;
    },

    copyFetch: (state, action) => state,

    createQuestionnaireFetch: (state, action) => state,

    addQuestionnaire: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      const targetProject = newState.data.find(
        (project: any) => project.id === action.payload.projectId
      );
      targetProject.questionnaires = [
        ...(targetProject.questionnaires || []),
        action.payload,
      ];
      return newState;
    },

    updateQuestionnaireFetch: (state, action) => state,

    updateQuestionnaire: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      const project = newState.data.find(
        (p: any) => p.id === action.payload.projectId
      );
      if (action.payload.archived) {
        project.questionnaires = project.questionnaires.filter(
          (questionnaire: any) => questionnaire.id !== action.payload.id
        );
      } else {
        const targetQuestionnaire = project.questionnaires.find(
          (questionnaire: any) => questionnaire.id === action.payload.id
        );
        Object.assign(targetQuestionnaire, action.payload);
      }
      return newState;
    },

    addSectionFetch: (state, action) => state,

    removeSectionFetch: (state, action) => state,

    setNameAlreadyExists: (state, action) => ({
      ...state,
      nameAlreadyExists: action.payload,
    }),

    addQuestionnaireIdWithExistingName: (state, action) => {
      if (state.questionnaireIdsWithExistingName.includes(action.payload)) {
        return state;
      }
      return {
        ...state,
        questionnaireIdsWithExistingName: [
          ...state.questionnaireIdsWithExistingName,
          action.payload,
        ],
      };
    },

    removeQuestionnaireIdWithExistingName: (state, action) => ({
      ...state,
      questionnaireIdsWithExistingName:
        state.questionnaireIdsWithExistingName.filter(
          (id: number) => id !== action.payload
        ),
    }),

    removeAllQuestionnaireIdsWithExistingName: (state) => ({
      ...state,
      questionnaireIdsWithExistingName: [],
    }),

    setCreatedProjectId: (state, action) => ({
      ...state,
      createdProjectId: action.payload,
    }),

    generateExportFetch: (state, action) => ({
      ...state,
      generatingExport: true,
    }),

    generateExportSuccess: (state) => ({
      ...state,
      generatingExport: false,
    }),

    downloadFilesFetch: (state, action) => ({
      ...state,
      downloadingFiles: true,
    }),

    downloadFilesSuccess: (state) => ({
      ...state,
      downloadingFiles: false,
    }),

    changeSectionOrderFetch: (state, action) => state,

    changeSectionOrder: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      const { projectSection1Index, projectSection2Index, projectId } =
        action.payload;
      const project = newState.data.find((p: any) => p.id === projectId);
      const aux1 = { ...project.projectSections[projectSection1Index] };
      const aux2 = { ...project.projectSections[projectSection2Index] };
      project.projectSections[projectSection1Index] = {
        ...aux2,
        sortOrder: aux1.sortOrder,
      };
      project.projectSections[projectSection2Index] = {
        ...aux1,
        sortOrder: aux2.sortOrder,
      };
      return newState;
    },
  },
});
