import { memo, Fragment } from "react";
import Card from "react-bootstrap/Card";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles({
  card: {
    width: "16rem",
    height: "16rem",
    cursor: "pointer",
    borderRadius: "1rem",
    backgroundColor: "transparent",
    transition: "0.25s",
    "&:hover": {
      transform: "translateY(-0.75rem)",
    },
  },
  cardBody: {
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
    backgroundColor: "#FFFFFF",
  },
  footer: {
    color: "#FFFFFF",
    fontSize: "0.7rem",
    fontWeight: 200,
    borderBottomLeftRadius: "1rem !important",
    borderBottomRightRadius: "1rem !important",
  },
  smallTitle: {
    textTransform: "uppercase",
    fontSize: "0.8rem",
  },
  title: {
    fontWeight: 400,
  },
  bodyText: {
    fontSize: "0.9rem",
    fontWeight: 200,
  },
  italicText: {
    fontStyle: "italic",
  },
});

interface Props {
  title: string;
  smallTitle: string;
  className?: string;
  footerText: string;
  footerBackgroundColor?: string;
  bodyTexts: string[];
  bodyFinalText: string;
}

const SquareCard = ({
  className,
  title,
  smallTitle,
  bodyTexts,
  bodyFinalText,
  footerText,
  footerBackgroundColor = "#495057",
}: Props) => {
  const classes = useStyles();

  const truncateTitle = (): string => {
    if (title.length >= 56) {
      return title.substring(0, 53).concat("...");
    }
    return title;
  };

  return (
    <Card className={classnames(classes.card, "shadow text-center", className)}>
      <Card.Body
        className={classnames(
          "d-flex align-items-center flex-column justify-content-between text-dark",
          classes.cardBody
        )}
      >
        <span className={classes.smallTitle}>{smallTitle}</span>
        <h5 className={classnames("w-100", classes.title)}>
          {truncateTitle()}
        </h5>
        <div>
          <div className={classnames(classes.bodyText, "text-secondary")}>
            {bodyTexts.map((text: string) => (
              <Fragment key={text}>
                {text}
                <br />
              </Fragment>
            ))}
          </div>
          <div
            className={classnames(
              classes.bodyText,
              "mt-1 text-secondary",
              classes.italicText
            )}
          >
            {bodyFinalText}
          </div>
        </div>
      </Card.Body>
      <Card.Footer
        className={classnames(
          "d-flex align-items-center justify-content-center pr-1 pl-1",
          classes.footer
        )}
        style={{ backgroundColor: footerBackgroundColor }}
      >
        <span>{footerText}</span>
      </Card.Footer>
    </Card>
  );
};

SquareCard.defaultProps = {
  className: null,
  footerBackgroundColor: "#495057",
};

export default memo(SquareCard);
