/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  data: Array<any>;
  loading: boolean;
  error: Error | null;
  sectionNameExists: boolean;
  questionNameExists: boolean;
  cardToolbarDisabled: boolean;
}

export default createSlice({
  name: "sections",
  initialState: {
    data: [],
    loading: true,
    error: null,
    sectionNameExists: false,
    questionNameExists: false,
    cardToolbarDisabled: false,
  } as InitialState,
  reducers: {
    fetch: (state) => state,

    add: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.data = newState.data.map((section: any) => {
        const sectionWithNewOrder = { ...section };
        if (sectionWithNewOrder.sortOrder >= action.payload.sortOrder) {
          sectionWithNewOrder.sortOrder += 1;
        }
        return sectionWithNewOrder;
      });
      newState.data = [
        ...newState.data,
        { ...action.payload, isNew: true },
      ].sort((a, b) => a.sortOrder - b.sortOrder);
      newState.cardToolbarDisabled = false;
      return newState;
    },

    addAll: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
    }),

    addError: (state, action) => ({
      ...state,
      error: action.payload,
      cardToolbarDisabled: false,
    }),

    copyFetch: (state, action) => ({
      ...state,
      cardToolbarDisabled: true,
    }),

    copyQuestionFetch: (state, action) => ({
      ...state,
      cardToolbarDisabled: true,
    }),

    updateFetch: (state, action) => ({
      ...state,
      cardToolbarDisabled: true,
    }),

    update: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      const updatedSectionData = action.payload.sectionData;
      const sectionToUpdate = newState.data.find(
        (s: any) => s.id === updatedSectionData.id
      );

      if (action.payload.newQuestionId) {
        updatedSectionData.questions = updatedSectionData.questions.map(
          (question: any) => {
            if (question.id === action.payload.newQuestionId) {
              return { ...question, isNewQuestion: true };
            }
            return question;
          }
        );
      }

      Object.assign(sectionToUpdate, updatedSectionData);
      newState.sectionNameExists = false;
      newState.cardToolbarDisabled = false;
      return newState;
    },

    updateQuestionFetch: (state, action) => ({
      ...state,
      cardToolbarDisabled: true,
    }),

    updateQuestion: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      const questionToUpdate = newState.data
        .map((section: any) => section.questions)
        .flat()
        .find((question: any) => question.id === action.payload.id);

      // If the question was moved to another section
      if (
        action.payload.sectionId &&
        questionToUpdate.sectionId !== action.payload.sectionId
      ) {
        const oldSection = newState.data.find(
          (section: any) => section.id === questionToUpdate.sectionId
        );
        const newSection = newState.data.find(
          (section: any) => section.id === action.payload.sectionId
        );

        oldSection.questions = oldSection.questions.filter(
          (question: any) => question.id !== questionToUpdate.id
        );

        // TODO: Move also the reflexive questions to the new sections
        questionToUpdate.reflexiveRules = [];

        newSection.questions = [...newSection.questions, questionToUpdate];
      }

      Object.assign(questionToUpdate, action.payload);
      newState.cardToolbarDisabled = false;
      return newState;
    },

    updateAnswerOptionFetch: (state, action) => state,

    createAnswerOptionFetch: (state, action) => state,

    archiveFetch: (state, action) => ({
      ...state,
      cardToolbarDisabled: true,
    }),

    remove: (state, action) => {
      const newState = { ...state };
      newState.data = newState.data.filter(
        (section: any) => section.id !== action.payload
      );
      newState.cardToolbarDisabled = false;
      return newState;
    },

    archiveQuestionFetch: (state, action) => ({
      ...state,
      cardToolbarDisabled: true,
    }),

    changeOrderFetch: (state, action) => ({
      ...state,
      cardToolbarDisabled: true,
    }),

    changeOrder: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      const { section1Index, section2Index } = action.payload;
      const aux1 = { ...newState.data[section1Index] };
      const aux2 = { ...newState.data[section2Index] };
      newState.data[section1Index] = {
        ...aux2,
        sortOrder: aux1.sortOrder,
      };
      newState.data[section2Index] = { ...aux1, sortOrder: aux2.sortOrder };
      newState.cardToolbarDisabled = false;
      return newState;
    },

    changeQuestionOrderFetch: (state, action) => ({
      ...state,
      cardToolbarDisabled: true,
    }),

    changeQuestionOrder: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      const { sectionId, question1Index, question2Index } = action.payload;
      const targetSection = newState.data.find(
        (section: any) => section.id === sectionId
      );
      const aux1 = { ...targetSection.questions[question1Index] };
      const aux2 = { ...targetSection.questions[question2Index] };
      targetSection.questions[question1Index] = {
        ...aux2,
        sortOrder: aux1.sortOrder,
      };
      targetSection.questions[question2Index] = {
        ...aux1,
        sortOrder: aux2.sortOrder,
      };
      newState.cardToolbarDisabled = false;
      return newState;
    },

    deleteAnswerOptionFetch: (state, action) => state,

    createReflexiveRuleFetch: (state, action) => state,

    updateReflexiveRuleFetch: (state, action) => state,

    deleteReflexiveRuleFetch: (state, action) => state,

    singleQuestionFetch: (state, action) => state,

    setSectionNameExists: (state, action) => ({
      ...state,
      sectionNameExists: action.payload,
    }),

    setQuestionNameExists: (state, action) => ({
      ...state,
      questionNameExists: action.payload,
    }),
  },
});
