import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../redux/reducer";
import clientsSlice from "../redux/clients/slice";

const useClients = () => {
  const clients = useSelector<RootState, any>((state) => state.clients);
  const projects = useSelector<RootState, any>((state) => state.projects);
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (clients.data.length === 0) {
      dispatch(clientsSlice.actions.fetch());
    }
  }, []);

  const clientsWithProjects = { ...clients };

  if (projects.data.length > 0) {
    clientsWithProjects.data = clientsWithProjects.data.map((client: any) => {
      const clientWithProjects = { ...client };
      clientWithProjects.projects = projects.data.filter(
        (project: any) => project.clientId === client.id
      );
      return clientWithProjects;
    });
  }

  return clientsWithProjects;
};

export default useClients;
