import { all, takeEvery, put, call } from "redux-saga/effects";
import usersSlice from "./slice";
import { httpClient, api } from "../../utils";

function* backofficeUsersFetch() {
  try {
    const { data: users } = yield call(
      httpClient.get,
      api.users.findBackofficeUsers
    );
    yield put(usersSlice.actions.addBackofficeUsers(users));
  } catch (error) {
    console.log(error);
    yield put(usersSlice.actions.addError(error));
  }
}

function* updateUserFetch({ payload: userData }: any) {
  try {
    const { data: updatedUser } = yield call(
      httpClient.patch,
      api.users.update,
      userData
    );
    yield put(usersSlice.actions.updateUser(updatedUser));
  } catch (error) {
    console.log(error);
    yield put(usersSlice.actions.addError(error));
  }
}

function* createUserFetch({ payload: user }: any) {
  try {
    const { data: createdUser } = yield call(
      httpClient.post,
      api.users.create,
      user
    );
    yield put(usersSlice.actions.addUser(createdUser));
  } catch (error) {
    console.log(error);
    yield put(usersSlice.actions.addError(error));
  }
}

function* deleteUserFetch({ payload: userId }: any) {
  try {
    yield call(httpClient.delete, api.users.delete.replace(":id", userId));
    yield put(usersSlice.actions.deleteUser(userId));
  } catch (error) {
    console.log(error);
    yield put(usersSlice.actions.addError(error));
  }
}

export default function* usersSaga() {
  yield all([
    takeEvery(
      usersSlice.actions.backofficeUsersFetch.type,
      backofficeUsersFetch
    ),
    takeEvery(usersSlice.actions.updateUserFetch.type, updateUserFetch),
    takeEvery(usersSlice.actions.createUserFetch.type, createUserFetch),
    takeEvery(usersSlice.actions.deleteUserFetch.type, deleteUserFetch),
  ]);
}
