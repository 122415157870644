import useLoggedUser from "./useLoggedUser";

const useCanEditForm = (projectStatus: string): boolean => {
  const loggedUserRole:
    | "SUPER_ADMIN"
    | "ADMIN"
    | "SUPER_USER"
    | "USER"
    | string
    | null = useLoggedUser().role;

  switch (projectStatus) {
    case "PLANNING":
      return loggedUserRole === "SUPER_ADMIN" || loggedUserRole === "ADMIN";
    case "ACTIVE":
      return loggedUserRole === "SUPER_ADMIN" || loggedUserRole === "ADMIN";
    case "ANALYZING":
      return loggedUserRole === "SUPER_ADMIN";
    default:
      return false;
  }
};

export default useCanEditForm;
