import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import { useBackofficeUsers } from "../hooks";
import UsersTable from "../components/UsersTable";
import CreateUserModal from "../components/CreateUserModal";

const useStyles = createUseStyles({
  newUserButton: {
    marginLeft: "1px",
  },
});

const UsersPage = () => {
  const classes = useStyles();
  const users = useBackofficeUsers();
  const [showCreateUserModal, setShowCreateUserModal] =
    useState<boolean>(false);
  return (
    <>
      <Row className="mb-4">
        <Col
          xs={{ span: 10, offset: 1 }}
          className="d-flex justify-content-between align-items-center"
        >
          <h1>Users</h1>
        </Col>
      </Row>

      <Row>
        <Col
          xs={{ span: 10, offset: 1 }}
          className="d-flex justify-content-center align-items-center flex-column"
        >
          {users.isLoading && users.data.length === 0 ? (
            <Spinner animation="grow" variant="warning" />
          ) : (
            <>
              <UsersTable users={users.data} />
              <Button
                variant="outline-primary"
                className={classnames(
                  "align-self-start mt-2",
                  classes.newUserButton
                )}
                onClick={() => setShowCreateUserModal(true)}
              >
                New User
              </Button>
            </>
          )}
        </Col>
      </Row>

      {showCreateUserModal && (
        <CreateUserModal onHide={() => setShowCreateUserModal(false)} />
      )}
    </>
  );
};

export default UsersPage;
