import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { useAuth0 } from "@auth0/auth0-react";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Navbar, Footer, BrowserAlert } from "@ko2-consulting/leaf";
import { useIdleTimer } from "react-idle-timer";
import Routes from "./Routes";
import { useLoggedUser } from "./hooks";
import loggedUserSlice from "./redux/loggedUser/slice";
import clientsSlice from "./redux/clients/slice";
import { RootState } from "./redux/reducer";

const qs = require("query-string");

const useStyles = createUseStyles({
  "@global": {
    h1: {
      fontWeight: 300,
    },
    h2: {
      fontWeight: 300,
    },
    h3: {
      fontWeight: 300,
    },
    h4: {
      fontWeight: 300,
    },
    h5: {
      fontWeight: 300,
    },
    body: {
      overflowX: "hidden",
    },
  },
  loadingContainer: {
    height: "100vh",
  },
  pageContentContainer: {
    paddingBottom: "7rem",
    maxWidth: "1600px",
  },
});

const App = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
    user,
  } = useAuth0();
  const location = useLocation();
  const currentRoute = location.pathname.replace("/", "");
  const history = useHistory();
  const classes = useStyles();
  const loggedUser = useLoggedUser();
  const dispatch = useDispatch();
  const superUserClient = useSelector<RootState, any>(
    (state) => state.clients.superUserClient
  );
  const superUserClientError: Error | null = useSelector<
    RootState,
    Error | null
  >((state) => state.clients.superUserClientError);

  const handleLogout = () => {
    dispatch(loggedUserSlice.actions.addAccessToken(null));
    logout({
      returnTo: `${window.location.origin}?redirectTo=${location.pathname}${location.search}`,
    });
  };

  // Configure inactivity timeout
  useIdleTimer({
    timeout: Number(process.env.REACT_APP_INACTIVITY_TIMEOUT),
    onIdle: handleLogout,
  });

  useEffect(() => {
    if (isAuthenticated && user) {
      getAccessTokenSilently().then((accessToken) => {
        dispatch(loggedUserSlice.actions.addAccessToken(accessToken));
        dispatch(
          loggedUserSlice.actions.addRole(
            user["https://ko2consulting.com/roles"][0]
          )
        );
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (loggedUser.role) {
      const { redirectTo } = qs.parse(location.search);
      switch (loggedUser.role) {
        case "USER":
          logout({ returnTo: window.location.origin });
          break;
        case "SUPER_USER":
          dispatch(clientsSlice.actions.fetch());
          break;
        default: {
          if (redirectTo) {
            history.push(
              // eslint-disable-next-line no-useless-escape
              redirectTo.match(/^\/(\?[a-zA-Z0-9-\[\]\(\)=]*)?$/gm)?.length > 0
                ? "/dashboard"
                : redirectTo
            );
          }
          break;
        }
      }
    }
  }, [loggedUser, location]);

  useEffect(() => {
    if (superUserClient || superUserClientError) {
      history.push(`/c/${superUserClient?.name || "not-found"}`);
    }
  }, [superUserClient, superUserClientError]);

  const userIsAdmin = (): boolean =>
    loggedUser.role === "SUPER_ADMIN" || loggedUser.role === "ADMIN";

  if (
    isLoading ||
    (isAuthenticated && (!loggedUser.accessToken || !loggedUser.role)) ||
    loggedUser.role === "USER" ||
    (loggedUser.role === "SUPER_USER" &&
      !superUserClient &&
      !superUserClientError)
  ) {
    return (
      <Container
        fluid
        className={classnames(
          "w-100 d-flex align-items-center justify-content-center",
          classes.loadingContainer
        )}
      >
        <Spinner animation="grow" variant="warning" />
      </Container>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      redirectUri: `${window.location.origin}?redirectTo=${location.pathname}${location.search}`,
    });
    return null;
  }

  const dropdownItems: any[] = [
    {
      text: "Logout",
      onPress: handleLogout,
    },
  ];

  if (loggedUser.role === "SUPER_ADMIN") {
    dropdownItems.unshift({
      text: "Users",
      onPress: () => history.push("/users"),
    });
  }

  return (
    <>
      <Navbar
        links={
          userIsAdmin()
            ? [
                {
                  text: "Dashboard",
                  onPress: () => history.push("/dashboard"),
                  active: currentRoute === "dashboard",
                  disabled: false,
                  path: "/dashboard",
                },
                {
                  text: "Question Sets",
                  onPress: () => history.push("/question-sets"),
                  active: currentRoute === "question-sets",
                  disabled: false,
                  path: "/question-sets",
                },
                /*                 {
                  text: "Reports",
                  onPress: () => history.push("/reports"),
                  active: currentRoute === "reports",
                  disabled: true,
                }, */
              ]
            : []
        }
        brand={{
          text: "KO2 Stat",
          onPress: () => {
            if (userIsAdmin()) {
              history.push("/dashboard");
            }
          },
          active: true,
          disabled: false,
        }}
        dropdownItems={dropdownItems}
        username={user?.name}
      />
      <Container
        className="mt-5 position-relative min-vh-100 pr-0 pl-0 d-flex align-items-center flex-column"
        fluid
      >
        <BrowserAlert />
        <div className={classnames(classes.pageContentContainer, "w-100")}>
          <Routes />
        </div>
        <Footer text="© 2022 Ko2 Consulting LLC" />
      </Container>
    </>
  );
};

export default App;
