import { Route, Switch } from "react-router-dom";
import { NotFound } from "@ko2-consulting/leaf";
import {
  ReportsPage,
  ProjectsPage,
  QuestionsPage,
  ClientPage,
  UsersPage,
} from "./pages";
import { useLoggedUser } from "./hooks";

const Routes = () => {
  const loggedUserRole: string | null = useLoggedUser().role;
  const isAdmin: boolean =
    loggedUserRole === "SUPER_ADMIN" || loggedUserRole === "ADMIN";

  const isSuperAdmin: boolean = loggedUserRole === "SUPER_ADMIN";

  const isSuperUser: boolean = loggedUserRole === "SUPER_USER";

  return (
    <Switch>
      {isAdmin && <Route exact path="/dashboard" component={ProjectsPage} />}
      {isAdmin && (
        <Route exact path="/question-sets" component={QuestionsPage} />
      )}
      {isAdmin && <Route exact path="/reports" component={ReportsPage} />}
      {isSuperAdmin && <Route exact path="/users" component={UsersPage} />}
      {(isAdmin || isSuperUser) && (
        <Route
          exact
          path="/c/:clientName"
          component={isSuperUser ? ClientPage : ProjectsPage}
        />
      )}
      {isAdmin && (
        <Route exact path="/p/:projectName" component={ProjectsPage} />
      )}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
