import { useState, useRef, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { createUseStyles } from "react-jss";
import { Pencil, Check2 } from "react-bootstrap-icons";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  EditableText,
  ModalWithDropdown,
  ConfirmActionModal,
  DeleteRowButton,
} from "@ko2-consulting/leaf";
import { Dispatch } from "redux";
import usersSlice from "../redux/users/slice";
import { RootState } from "../redux/reducer";
import { roles, Auth0Utils } from "../utils";

const rolesDropdownOptions: any[] = Object.keys(roles).map(
  (roleId: string) => ({
    key: roleId,
    text: roles[roleId],
    value: roleId,
  })
);

const useStyles = createUseStyles({
  passwordReset: {
    color: "#da3749",
    cursor: "pointer",
  },
  editIcon: {
    cursor: "pointer",
  },
  spinner: {
    width: "1.1rem",
    height: "1.1rem",
  },
  disabledIcon: {
    opacity: 0.5,
  },
});

interface Props {
  user: any;
}

const UsersTableRow = ({ user }: Props) => {
  const classes = useStyles();
  const [editingUsername, setEditingUsername] = useState<boolean>(false);
  const [editingEmail, setEditingEmail] = useState<boolean>(false);
  const [editingRole, setEditingRole] = useState<boolean>(false);
  const [showResetPasswordModal, setShowResetPasswordModal] =
    useState<boolean>(false);
  const [showDeleteUserModal, setShowDeleteUserModal] =
    useState<boolean>(false);
  const usernameRef = useRef<string>(user.username);
  const emailRef = useRef<string>(user.email);
  const dispatch: Dispatch = useDispatch();
  const isLoading: boolean = useSelector<RootState, boolean>(
    (state) => state.users.isLoading
  );

  useEffect(() => {
    if (!isLoading) {
      setEditingUsername(false);
      setEditingEmail(false);
      setEditingRole(false);
    }
  }, [isLoading]);

  const updateUser = (userData: any) =>
    dispatch(usersSlice.actions.updateUserFetch({ ...userData, id: user.id }));

  const handleUsernameEditIconClick = () => {
    if (editingUsername) {
      updateUser({ username: usernameRef.current });
    } else {
      setEditingUsername(true);
    }
  };

  const handleEmailEditIconClick = () => {
    if (editingEmail) {
      updateUser({ email: emailRef.current });
    } else {
      setEditingEmail(true);
    }
  };

  const handleResetPasswordConfirm = async () => {
    await Auth0Utils.resetPassword(user.email);
    setShowResetPasswordModal(false);
  };

  const handleDeleteUserConfirm = () => {
    dispatch(usersSlice.actions.deleteUserFetch(user.id));
    setShowDeleteUserModal(false);
  };

  const EditUsernameIcon = editingUsername ? Check2 : Pencil;
  const EditEmailIcon = editingEmail ? Check2 : Pencil;

  return (
    <>
      <tr>
        <td className="w-25 position-relative">
          <DeleteRowButton onClick={() => setShowDeleteUserModal(true)} />
          <div className="d-flex justify-content-between align-items-center">
            {editingUsername ? (
              <EditableText
                formGroupClassName="mb-0"
                textRef={usernameRef}
                onChange={(username: string) => {
                  usernameRef.current = username;
                }}
              />
            ) : (
              user.username
            )}
            {editingUsername && isLoading ? (
              <Spinner
                className={classes.spinner}
                animation="grow"
                variant="warning"
              />
            ) : (
              <EditUsernameIcon
                className={classnames(
                  classes.editIcon,
                  isLoading ? classes.disabledIcon : null
                )}
                size={editingUsername ? "1.3rem" : "1.1rem"}
                onClick={!isLoading ? handleUsernameEditIconClick : undefined}
              />
            )}
          </div>
        </td>
        <td className="w-25">
          <div className="d-flex justify-content-between align-items-center">
            {roles[user.auth0Role]}
            {editingRole && isLoading ? (
              <Spinner
                className={classes.spinner}
                animation="grow"
                variant="warning"
              />
            ) : (
              <Pencil
                className={classnames(
                  classes.editIcon,
                  isLoading ? classes.disabledIcon : null
                )}
                size="1.1rem"
                onClick={!isLoading ? () => setEditingRole(true) : undefined}
              />
            )}
          </div>
        </td>
        <td className="w-25">
          <div className="d-flex justify-content-between align-items-center">
            {editingEmail ? (
              <EditableText
                formGroupClassName="mb-0"
                textRef={emailRef}
                onChange={(email: string) => {
                  emailRef.current = email;
                }}
              />
            ) : (
              user.email
            )}
            {editingEmail && isLoading ? (
              <Spinner
                className={classes.spinner}
                animation="grow"
                variant="warning"
              />
            ) : (
              <EditEmailIcon
                className={classnames(
                  classes.editIcon,
                  isLoading ? classes.disabledIcon : null
                )}
                size={editingEmail ? "1.3rem" : "1.1rem"}
                onClick={!isLoading ? handleEmailEditIconClick : undefined}
              />
            )}
          </div>
        </td>
        <td className={classnames("w-25", classes.passwordReset)}>
          <div
            className={isLoading ? classes.disabledIcon : ""}
            onClick={
              !isLoading ? () => setShowResetPasswordModal(true) : undefined
            }
          >
            Send password reset link
          </div>
        </td>
      </tr>

      {editingRole && !isLoading && (
        <ModalWithDropdown
          onHide={() => setEditingRole(false)}
          title={`What Role do you want to assign to ${user.username}?`}
          confirmText="Assign it"
          cancelText="Cancel"
          loading={false}
          dropdownPlaceholder="Roles"
          dropdownOptions={rolesDropdownOptions}
          defaultDropownValue={user.auth0Role}
          onConfirm={(newRole: string) => updateUser({ auth0Role: newRole })}
        />
      )}

      {showResetPasswordModal && !isLoading && (
        <ConfirmActionModal
          onHide={() => setShowResetPasswordModal(false)}
          title={`Do you want to send a Password Reset Link to ${user.username}?`}
          description=""
          show
          confirmText="Yes, Send them Password Reset Link"
          cancelText="Cancel"
          onConfirmPress={handleResetPasswordConfirm}
        />
      )}

      {showDeleteUserModal && (
        <ConfirmActionModal
          onHide={() => setShowDeleteUserModal(false)}
          title={`Do you want to delete user ${user.username}?`}
          description=""
          show
          confirmText="Yes, delete them"
          cancelText="Cancel"
          onConfirmPress={handleDeleteUserConfirm}
        />
      )}
    </>
  );
};

export default UsersTableRow;
