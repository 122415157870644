import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  accessToken: string | null;
  role: string | null;
}

export default createSlice({
  name: "loggedUser",
  initialState: {
    accessToken: null,
    role: null,
  } as InitialState,
  reducers: {
    addAccessToken: (state, action) => ({
      ...state,
      accessToken: action.payload,
    }),

    addRole: (state, action) => ({
      ...state,
      role: action.payload,
    }),
  },
});
