import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormCheck from "react-bootstrap/FormCheck";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import dayjs from "dayjs";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import { LockFill, Link45deg } from "react-bootstrap-icons";
import { NoData, ConfirmActionModal } from "@ko2-consulting/leaf";
import { useAuth0 } from "@auth0/auth0-react";
import { Dispatch } from "redux";
import Countdown from "react-countdown";
import { RootState } from "../redux/reducer";
import QuestionnairesTable from "../components/QuestionnairesTable";
import clientsSlice from "../redux/clients/slice";

const useStyles = createUseStyles({
  title: {
    wordWrap: "break-word",
  },
  greyText: {
    color: "#7b838a",
  },
  table: {
    backgroundColor: "#ffffff",
  },
});

const ClientPage = () => {
  const classes = useStyles();
  const { logout } = useAuth0();
  const superUserClient = useSelector<RootState, any>(
    (state) => state.clients.superUserClient
  );
  const dataDeleted = useSelector<RootState, boolean>(
    (state) => state.clients.dataDeleted
  );
  const deletingData = useSelector<RootState, boolean>(
    (state) => state.clients.deletingData
  );
  const [acceptDeleteChecked, setAcceptDeleteChecked] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const dispatch: Dispatch = useDispatch();

  if (!superUserClient) {
    return (
      <Row className="w-100 ml-1">
        <Col xs={{ span: 10, offset: 1 }}>
          <NoData
            title="This User doen't have any Client associated"
            description="Contact support"
          />
        </Col>
      </Row>
    );
  }

  if (dataDeleted) {
    return (
      <Row className="w-100 ml-1">
        <Col
          xs={{ span: 10, offset: 1 }}
          className="d-flex align-items-center justify-content-center flex-column"
        >
          <Spinner animation="grow" variant="warning" />
          <h4 className="mt-5">
            All data was deleted, you will be logged out after
            <Countdown
              date={Date.now() + 5000}
              renderer={({ seconds }) => (
                <span>{` ${seconds} seconds...`}</span>
              )}
              onComplete={() => logout({ returnTo: window.location.origin })}
              onStart={() => window.scrollTo(0, 0)}
            />
          </h4>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row className="w-100 ml-1">
        <Col xs={{ span: 10, offset: 1 }}>
          <h1>{`${superUserClient.title} Projects`}</h1>
        </Col>
      </Row>

      <Row className="w-100 mt-5 ml-1">
        <Col xs={{ span: 10, offset: 1 }}>
          {superUserClient.projects.length === 0 ? (
            <NoData
              title="This Client doen't have any projects"
              description="Contact support to add any"
            />
          ) : (
            superUserClient.projects.map((project: any, index: number) => (
              <Row
                key={project.id}
                className={classnames("w-100 mb-5", index > 0 ? "mt-5" : null)}
              >
                <Col
                  xs={12}
                  className="d-flex justify-content-between ml-0 mr-0"
                >
                  <h2 className={classnames(classes.title, "w-50")}>
                    {project.title}
                  </h2>
                  {project.dueDate && (
                    <h2 className={classes.greyText}>{`Due by ${dayjs(
                      project.dueDate
                    ).format("MM.DD.YYYY")}`}</h2>
                  )}
                </Col>
                <Col
                  xs={12}
                  className={classnames(
                    "d-flex justify-content-between ml-0 mr-0 mt-3",
                    classes.greyText
                  )}
                >
                  <div className="w-50">{project.description}</div>
                  <div className="d-flex align-items-center">
                    {project.questionnaireLoginRequired ? (
                      <>
                        Access with ID and Password
                        <LockFill size="1rem" className="ml-2" />
                      </>
                    ) : (
                      <>
                        Access with Link
                        <Link45deg size="1rem" className="ml-2" />
                      </>
                    )}
                  </div>
                </Col>

                <Col xs={12} className="mt-4 mb-1">
                  <QuestionnairesTable
                    editMode={false}
                    questionnaires={project.questionnaires}
                    idsWithError={[]}
                    onIdsWithErrorChange={() => {}}
                    onQuestionnairesToCreateChange={() => {}}
                    onQuestionnairesToUpdateChange={() => {}}
                    projectId={project.id}
                    questionnairesToCreate={[]}
                    questionnairesToUpdate={[]}
                    tableClassName={classes.table}
                    passwordEnabled={project.questionnaireLoginRequired}
                    setQuestionnaireIdToRevertSubmit={() => {}}
                  />
                </Col>
              </Row>
            ))
          )}
        </Col>
      </Row>

      <Row className="w-100 ml-1 mt-5">
        <Col xs={{ span: 10, offset: 1 }}>
          <h1>Actions</h1>
        </Col>
      </Row>
      <Row className="w-100 ml-1 mt-4 mb-5">
        <Col xs={{ span: 10, offset: 1 }}>
          <h3>{`Request permanent deletion of the data from ${superUserClient.title} on KO2 Stat?`}</h3>

          <FormCheck
            id="accept-delete-switch"
            className="mt-4"
            type="switch"
            label={`"I understand that by permanently deleting this data, neither me or anyone in my organization will have access to it in the future, and KO2 Stat won't be able to recover it."`}
            checked={acceptDeleteChecked}
            onChange={(event: any) =>
              setAcceptDeleteChecked(event.target.checked)
            }
          />

          <Button
            className="mt-4"
            variant="danger"
            size="lg"
            disabled={!acceptDeleteChecked}
            onClick={() => setShowDeleteModal(true)}
          >
            Continue
          </Button>
        </Col>
      </Row>

      {showDeleteModal && (
        <ConfirmActionModal
          show
          onHide={() => setShowDeleteModal(false)}
          title="Danger zone"
          description={`Are you sure you want to permanently delete all of the data from ${superUserClient.title} on KO2 Stat? Once you press the red button below, there will be no way to recover it. It will be permanently deleted.`}
          confirmText={`Yes, Delete ${superUserClient.title}'s Data PERMANENTLY`}
          cancelText="Cancel (Don't Delete!)"
          onConfirmPress={() =>
            dispatch(clientsSlice.actions.deleteAllDataFetch())
          }
          loading={deletingData}
        />
      )}
    </>
  );
};

export default ClientPage;
