import { combineReducers } from "redux";
import sectionsSlice from "./sections/slice";
import loggedUserSlice from "./loggedUser/slice";
import clientsSlice from "./clients/slice";
import projectsSlice from "./projects/slice";
import usersSlice from "./users/slice";

const reducer = combineReducers({
  sections: sectionsSlice.reducer,
  loggedUser: loggedUserSlice.reducer,
  clients: clientsSlice.reducer,
  projects: projectsSlice.reducer,
  users: usersSlice.reducer,
});

export type RootState = ReturnType<typeof reducer>;
export default reducer;
