import { all, call, put, select, takeEvery } from "redux-saga/effects";
import clientsSlice from "./slice";
import projectsSlice from "../projects/slice";
import { api, httpClient } from "../../utils";

function* fetch() {
  try {
    const { data } = yield call(httpClient.get, api.clients.get);
    if (Array.isArray(data)) {
      yield put(clientsSlice.actions.addAll(data));
    } else {
      yield put(clientsSlice.actions.addSuperUserClient(data));
    }
  } catch (error) {
    console.log(error);
    if (error.response?.status === 404) {
      yield put(clientsSlice.actions.addSuperUserClientError(error));
    } else {
      yield put(clientsSlice.actions.addError(error));
    }
  }
}

function* createFetch({ payload: { clientData, callback } }: any) {
  try {
    const { data: newClient } = yield call(
      httpClient.post,
      api.clients.create,
      clientData
    );
    callback();
    yield put(clientsSlice.actions.addSingle(newClient));
  } catch (error) {
    console.log(error);
    if (error.response?.status === 409) {
      yield put(clientsSlice.actions.setNameAlreadyExists(true));
    } else {
      yield put(clientsSlice.actions.addError(error));
    }
  }
}

function* createOrUpdateRepresentative(representativeData: any) {
  yield call(
    httpClient.put,
    api.extraRepresentatives.createOrUpdate,
    representativeData
  );
}

function* updateFetch({
  payload: { clientData, extraRepresentatives, callback },
}: any) {
  try {
    yield all(
      extraRepresentatives.map((representative: any) =>
        call(createOrUpdateRepresentative, representative)
      )
    );
    const { data: updatedClient } = yield call(
      httpClient.patch,
      api.clients.update,
      clientData
    );

    callback();
    yield put(clientsSlice.actions.update(updatedClient));
  } catch (error) {
    console.log(error);
    if (error.response?.status === 409) {
      yield put(clientsSlice.actions.setNameAlreadyExists(true));
    } else {
      yield put(clientsSlice.actions.addError(error));
    }
  }
}

function* archiveFetch({ payload: id }: any) {
  try {
    yield call(httpClient.patch, api.clients.update, { id, archived: true });
    yield put(clientsSlice.actions.remove(id));

    // Remove related projects
    const {
      projects: { data: projects },
    } = yield select();
    yield all(
      projects
        .filter((project: any) => project.clientId === id)
        .map((project: any) => put(projectsSlice.actions.remove(project.id)))
    );
  } catch (error) {
    console.log(error);
    yield put(clientsSlice.actions.addError(error));
  }
}

function* copyFetch({ payload: clientId }: any) {
  try {
    const { data: clientCopy } = yield call(
      httpClient.post,
      api.clients.copy.replace(":id", clientId)
    );
    const relatedProjects = [...clientCopy.projects];
    delete clientCopy.projects;
    yield put(clientsSlice.actions.addSingle(clientCopy));

    // Add related projects
    yield all(
      relatedProjects.map((project: any) =>
        put(projectsSlice.actions.addSingle(project))
      )
    );
  } catch (error) {
    console.log(error);
    yield put(clientsSlice.actions.addError(error));
  }
}

function* superUsersToSelectFetch() {
  try {
    const { data: superUsersToSelect } = yield call(
      httpClient.get,
      api.users.findAvailableSuperUsers
    );
    yield put(clientsSlice.actions.addSuperUsersToSelect(superUsersToSelect));
  } catch (error) {
    console.log(error);
    yield put(clientsSlice.actions.addError(error));
  }
}

function* deleteAllDataFetch() {
  try {
    yield call(httpClient.delete, api.clients.deleteAllData);
    yield put(clientsSlice.actions.deleteAllDataSuccess());
  } catch (error) {
    console.log(error);
    yield put(clientsSlice.actions.addError(error));
  }
}

function* clientsSaga() {
  yield all([
    takeEvery(clientsSlice.actions.fetch.type, fetch),
    takeEvery(clientsSlice.actions.createFetch.type, createFetch),
    takeEvery(clientsSlice.actions.updateFetch.type, updateFetch),
    takeEvery(clientsSlice.actions.archiveFetch.type, archiveFetch),
    takeEvery(clientsSlice.actions.copyFetch.type, copyFetch),
    takeEvery(
      clientsSlice.actions.superUsersToSelectFetch.type,
      superUsersToSelectFetch
    ),
    takeEvery(clientsSlice.actions.deleteAllDataFetch.type, deleteAllDataFetch),
  ]);
}

export default clientsSaga;
