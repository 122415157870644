import {
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { createUseStyles } from "react-jss";
import {
  Clipboard,
  Eye,
  EyeSlash,
  BoxArrowUpRight,
} from "react-bootstrap-icons";
import copyToClipboard from "copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import passwordGenerator from "generate-password";
import {
  EditableText,
  IconWithTooltip,
  DeleteRowButton,
  StringUtils,
} from "@ko2-consulting/leaf";
import PasswordValidator from "password-validator";
import { RootState } from "../redux/reducer";
import projectsSlice from "../redux/projects/slice";
import RevertSubmitQuestionnaireButton from "./RevertSubmitQuestionnaireButton";

const passwordValidatorSchema = new PasswordValidator();
passwordValidatorSchema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .not()
  .spaces();

const useStyles = createUseStyles({
  link: {
    width: "87%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  linkIcon: {
    color: "inherit",
    "&:hover": {
      color: "inherit",
    },
  },
  password: {
    fontFamily: "text-security-disc",
    "-webkit-text-security": "disc",
    letterSpacing: "0.1rem",
  },
});

interface Props {
  questionnaire: any;
  onChange: Function;
  editMode: boolean;
  idsWithError: number[];
  onIdsWithErrorChange: Function;
  passwordEnabled: boolean;
  setQuestionnaireIdToRevertSubmit: Function;
}

const QuestionnaireEditRow = (
  {
    questionnaire,
    onChange,
    editMode,
    idsWithError,
    onIdsWithErrorChange,
    passwordEnabled,
    setQuestionnaireIdToRevertSubmit,
  }: Props,
  ref: any
) => {
  const classes = useStyles();
  const [copyMessage, setCopyMessage] = useState<string>("Copy link");
  const [nameHasError, setNameHasError] = useState<boolean>(false);
  const [passwordHasError, setPasswordHasError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const nameRef = useRef<string>(questionnaire.name || "");
  const passwordRef = useRef<string>(questionnaire.password || "");
  const linkHash: string = useMemo<string>(
    () =>
      passwordGenerator.generate({
        length: 8,
      }),
    []
  );
  const questionnaireIdsWithExistingName: number[] = useSelector<
    RootState,
    any
  >((state) => state.projects.questionnaireIdsWithExistingName);
  const dispatch: Dispatch = useDispatch();

  const buildLink = () =>
    `${process.env.REACT_APP_CLIENT_APP_BASE_URL}/f/${linkHash}/${
      nameRef.current || "form-id"
    }`;

  const [link, setLink] = useState<string>(questionnaire.link || buildLink());

  useImperativeHandle(ref, () => ({
    reset: () => {
      if (questionnaire?.id) {
        nameRef.current = questionnaire.name;
        passwordRef.current = questionnaire.password;
      }
    },
  }));

  useEffect(() => {
    if (questionnaire.isNew) {
      onChange(
        questionnaire.id,
        questionnaire.name,
        questionnaire.password,
        questionnaire.isNew,
        false,
        questionnaire.userId,
        link
      );
    }
  }, []);

  useEffect(() => {
    if (nameHasError && !idsWithError.includes(questionnaire.id)) {
      onIdsWithErrorChange([...idsWithError, questionnaire.id]);
    } else if (!nameHasError && idsWithError.includes(questionnaire.id)) {
      onIdsWithErrorChange(
        idsWithError.filter((id: number) => id !== questionnaire.id)
      );
    }
  }, [nameHasError]);

  useEffect(() => {
    if (passwordHasError && !idsWithError.includes(questionnaire.id)) {
      onIdsWithErrorChange([...idsWithError, questionnaire.id]);
    } else if (!passwordHasError && idsWithError.includes(questionnaire.id)) {
      onIdsWithErrorChange(
        idsWithError.filter((id: number) => id !== questionnaire.id)
      );
    }
  }, [passwordHasError]);

  useEffect(() => {
    if (!showPassword) {
      setPasswordHasError(false);
    }
  }, [showPassword]);

  const handleCopy = () => {
    copyToClipboard(questionnaire.link);
    setCopyMessage("Copied");
  };

  const onCopyIconMouseLeave = () =>
    setTimeout(() => setCopyMessage("Copy link"), 500);

  const ShowPasswordIcon = showPassword ? EyeSlash : Eye;

  const onNameChange = (name: string) => {
    const normalizedName: string = StringUtils.normalizeName(name);
    nameRef.current = normalizedName;
    const newLink = buildLink();
    if (normalizedName.trim().length > 0) {
      setNameHasError(false);
    }
    onChange(
      questionnaire.id,
      normalizedName,
      questionnaire.password,
      questionnaire.isNew,
      false,
      questionnaire.userId,
      newLink
    );
    if (questionnaireIdsWithExistingName.includes(questionnaire.id)) {
      dispatch(
        projectsSlice.actions.removeQuestionnaireIdWithExistingName(
          questionnaire.id
        )
      );
    }
    setLink(newLink);
  };

  const onPasswordChange = (password: string) => {
    const normalizedPassword: string = password
      .trim()
      .replaceAll("<br>", "")
      .replaceAll("&amp;", "");
    passwordRef.current = normalizedPassword;
    onChange(
      questionnaire.id,
      questionnaire.name,
      normalizedPassword,
      questionnaire.isNew,
      false,
      questionnaire.userId,
      link
    );
    setPasswordHasError(!passwordValidatorSchema.validate(normalizedPassword));
  };

  return (
    <tr key={questionnaire.id}>
      <td className="position-relative">
        {editMode && (
          <DeleteRowButton
            onClick={() =>
              onChange(
                questionnaire.id,
                questionnaire.name,
                questionnaire.password,
                questionnaire.isNew,
                true,
                questionnaire.userId,
                link
              )
            }
          />
        )}
        {editMode ? (
          <EditableText
            textRef={nameRef}
            formGroupClassName="mb-0"
            onChange={onNameChange}
            onError={(error: string) => setNameHasError(!!error)}
            customErrorMessage={
              questionnaireIdsWithExistingName.includes(questionnaire.id)
                ? "That name already exists."
                : undefined
            }
            required
          />
        ) : (
          nameRef.current
        )}
      </td>
      {passwordEnabled && (
        <td>
          <div className="d-flex align-items-center justify-content-between">
            {editMode ? (
              <EditableText
                className={!showPassword ? classes.password : ""}
                textRef={passwordRef}
                formGroupClassName="mb-0"
                onChange={onPasswordChange}
                customErrorMessage={
                  passwordHasError
                    ? "At least one lowercase, uppercase and number. 8 characters long or more."
                    : undefined
                }
                required
              />
            ) : (
              <div className={!showPassword ? classes.password : ""}>
                {passwordRef.current}
              </div>
            )}
            <IconWithTooltip
              Icon={ShowPasswordIcon}
              iconSize="1.1rem"
              text={showPassword ? "Hide" : "Show"}
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        </td>
      )}
      <td>
        <div className="d-flex align-items-center justify-content-between">
          {StringUtils.capitalize(
            questionnaire.submitState?.toLowerCase() || "Empty"
          )}
          {questionnaire.submitState?.toLowerCase() === "submitted" && (
            <RevertSubmitQuestionnaireButton
              questionnaireId={questionnaire.id}
              setQuestionnaireIdToRevertSubmit={
                setQuestionnaireIdToRevertSubmit
              }
            />
          )}
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center justify-content-between">
          {questionnaire.isNew ? (
            "-"
          ) : (
            <>
              <div className={classes.link} title={questionnaire.link}>
                {link}
              </div>
              <IconWithTooltip
                Icon={Clipboard}
                iconSize="0.9rem"
                text={copyMessage}
                onClick={handleCopy}
                onMouseLeave={onCopyIconMouseLeave}
              />
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className={classes.linkIcon}
              >
                <IconWithTooltip
                  Icon={BoxArrowUpRight}
                  iconSize="0.9rem"
                  text="Open Form"
                  onClick={() => {}}
                  onMouseLeave={() => {}}
                />
              </a>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default forwardRef(QuestionnaireEditRow);
