import {
  actionChannel,
  all,
  call,
  put,
  take,
  takeEvery,
} from "redux-saga/effects";
import FileDownload from "js-file-download";
import dayjs from "dayjs";
import projectsSlice from "./slice";
import { api, httpClient } from "../../utils";

function* fetch() {
  try {
    const { data: projects } = yield call(httpClient.get, api.projects.get);
    yield put(projectsSlice.actions.addAll(projects));
  } catch (error) {
    console.log(error);
    yield put(projectsSlice.actions.addError(error));
  }
}

function* createFetch({ payload: projectData }: any) {
  let createdProject = null;
  try {
    const { data } = yield call(
      httpClient.post,
      api.projects.create,
      projectData
    );
    yield all([
      put(projectsSlice.actions.addSingle(data)),
      put(projectsSlice.actions.setCreatedProjectId(data.id)),
    ]);
    createdProject = data;
  } catch (error) {
    console.log(error);
    if (error.response?.status === 409) {
      yield put(projectsSlice.actions.setNameAlreadyExists(true));
    } else {
      yield put(projectsSlice.actions.addError(error));
    }
  }
  return createdProject;
}

function* updateFetch({ payload: projectData }: any) {
  try {
    const { data: updatedProject } = yield call(
      httpClient.patch,
      api.projects.update,
      projectData
    );
    yield put(projectsSlice.actions.update(updatedProject));
  } catch (error) {
    if (error.response?.status === 409) {
      yield put(projectsSlice.actions.setNameAlreadyExists(true));
    } else {
      yield put(projectsSlice.actions.addError(error));
    }
  }
}

function* archiveFetch({ payload: id }: any) {
  try {
    yield call(httpClient.patch, api.projects.update, { id, archived: true });
    yield put(projectsSlice.actions.remove(id));
  } catch (error) {
    console.log(error);
    yield put(projectsSlice.actions.addError(error));
  }
}

function* copyFetch({ payload: projectId }: any) {
  try {
    const { data: projectCopy } = yield call(
      httpClient.post,
      api.projects.copy.replace(":id", projectId)
    );
    yield put(projectsSlice.actions.addSingle(projectCopy));
  } catch (error) {
    console.log(error);
    yield put(projectsSlice.actions.addError(error));
  }
}

function* createQuestionnaireFetch(
  { payload: { questionnaireData, callback } }: any,
  projectId?: number
) {
  try {
    const questionnaireWithProjectId = { ...questionnaireData };
    if (projectId) {
      questionnaireWithProjectId.projectId = projectId;
    }
    delete questionnaireWithProjectId.id;
    const { data: savedQuestionnaire } = yield call(
      httpClient.post,
      api.questionnaires.create,
      questionnaireWithProjectId
    );
    if (callback) {
      callback();
    }
    yield all([
      put(
        projectsSlice.actions.removeQuestionnaireIdWithExistingName(
          questionnaireData.id
        )
      ),
      put(projectsSlice.actions.addQuestionnaire(savedQuestionnaire)),
    ]);
  } catch (error) {
    console.log(error);
    if (error.response?.status === 409) {
      yield put(
        projectsSlice.actions.addQuestionnaireIdWithExistingName(
          questionnaireData.id
        )
      );
    } else {
      yield put(projectsSlice.actions.addError(error));
    }
  }
}

function* updateQuestionnaireFetch({
  payload: { questionnaireData, callback },
}: any) {
  try {
    const { data: updatedQuestionnaire } = yield call(
      httpClient.patch,
      api.questionnaires.update,
      questionnaireData
    );

    if (callback) {
      callback();
    }

    yield put(
      projectsSlice.actions.removeQuestionnaireIdWithExistingName(
        questionnaireData.id
      )
    );
    yield put(projectsSlice.actions.updateQuestionnaire(updatedQuestionnaire));
  } catch (error) {
    console.log(error);
    if (error.response?.status === 409) {
      yield put(
        projectsSlice.actions.addQuestionnaireIdWithExistingName(
          questionnaireData.id
        )
      );
    } else {
      yield put(projectsSlice.actions.addError(error));
    }
  }
}

function* addSectionFetch(
  { payload: { id, sectionId } }: any,
  projectId?: number
) {
  try {
    const { data: updatedProject } = yield call(
      httpClient.post,
      api.projects.addSection
        .replace(":id", projectId || id)
        .replace(":sectionId", sectionId)
    );
    yield put(projectsSlice.actions.update(updatedProject));
  } catch (error) {
    console.log(error);
    yield put(projectsSlice.actions.addError(error));
  }
}

function* removeSectionFetch({ payload: { id, sectionId } }: any) {
  try {
    const { data: updatedProject } = yield call(
      httpClient.delete,
      api.projects.removeSection
        .replace(":id", id)
        .replace(":sectionId", sectionId)
    );
    yield put(projectsSlice.actions.update(updatedProject));
  } catch (error) {
    console.log(error);
    yield put(projectsSlice.actions.addError(error));
  }
}

// When a project is created, we need to create it before creating a questionnaire
function* watchCreateProject(): any {
  const createProjectChannel = yield actionChannel([
    projectsSlice.actions.createFetch.type,
    projectsSlice.actions.createQuestionnaireFetch.type,
    projectsSlice.actions.addSectionFetch.type,
  ]);

  let result = null;

  while (true) {
    const action = yield take(createProjectChannel);
    switch (action.type) {
      case projectsSlice.actions.createFetch.type:
        result = yield call(createFetch, action);
        break;
      case projectsSlice.actions.createQuestionnaireFetch.type:
        yield call(createQuestionnaireFetch, action, result?.id);
        break;
      case projectsSlice.actions.addSectionFetch.type:
        yield call(addSectionFetch, action, result?.id);
        break;
      default:
        return null;
    }
  }
}

function* generateExportFetch({ payload: { id, name } }: any) {
  try {
    const { data: xls } = yield call(
      httpClient.get,
      api.projects.generateExport.replace(":id", id),
      { responseType: "blob" }
    );
    FileDownload(xls, `export-${name}_${dayjs().format("YYYY-MM-DD")}.xlsx`);
    yield put(projectsSlice.actions.generateExportSuccess());
  } catch (error) {
    console.log(error);
    yield put(projectsSlice.actions.addError(error));
  }
}

function* downloadFile({ filename, questionnaireId }: any) {
  const { data: file } = yield call<any>(
    httpClient.get,
    api.files.download
      .replace(":filename", filename)
      .replace(":questionnaireId", questionnaireId),
    { responseType: "blob" }
  );
  FileDownload(file, filename);
}

function* downloadFilesFetch({ payload: { id } }: any) {
  try {
    const { data: availableFiles } = yield call(
      httpClient.get,
      api.projects.availableFiles.replace(":id", id)
    );
    yield all(
      availableFiles.map((fileDto: any) => call(downloadFile, fileDto))
    );
    yield put(projectsSlice.actions.downloadFilesSuccess());
  } catch (error) {
    console.log(error);
    yield put(projectsSlice.actions.addError(error));
  }
}

function* changeSectionOrderFetch({
  payload: { section, direction, project },
}: any) {
  const projectSectionIndex = project.projectSections.findIndex(
    (projectSection: any) => projectSection.sectionId === section.id
  );

  // The last and the first cannot be moved outside existing range
  if (
    (projectSectionIndex === 0 && direction === "UP") ||
    (projectSectionIndex === project.projectSections.length - 1 &&
      direction === "DOWN")
  ) {
    return;
  }

  let theOtherIndex;
  if (direction === "UP") {
    theOtherIndex = projectSectionIndex - 1;
  } else {
    theOtherIndex = projectSectionIndex + 1;
  }

  const theOtherProjectSection = project.projectSections[theOtherIndex];
  try {
    yield all([
      call<any>(
        httpClient.put,
        api.projects.changeSectionOrder
          .replace(":id", project.id)
          .replace(":sectionId", section.id),
        {
          sortOrder: theOtherProjectSection.sortOrder,
        }
      ),
      call<any>(
        httpClient.put,
        api.projects.changeSectionOrder
          .replace(":id", project.id)
          .replace(":sectionId", theOtherProjectSection.section.id),
        {
          sortOrder: project.projectSections[projectSectionIndex].sortOrder,
        }
      ),
    ]);
    yield put(
      projectsSlice.actions.changeSectionOrder({
        projectSection1Index: projectSectionIndex,
        projectSection2Index: theOtherIndex,
        projectId: project.id,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(projectsSlice.actions.addError(error));
  }
}

function* projectsSaga() {
  yield all([
    takeEvery(projectsSlice.actions.fetch.type, fetch),
    takeEvery(projectsSlice.actions.updateFetch.type, updateFetch),
    takeEvery(projectsSlice.actions.archiveFetch.type, archiveFetch),
    takeEvery(projectsSlice.actions.copyFetch.type, copyFetch),
    takeEvery(
      projectsSlice.actions.updateQuestionnaireFetch.type,
      updateQuestionnaireFetch
    ),
    takeEvery(
      projectsSlice.actions.removeSectionFetch.type,
      removeSectionFetch
    ),
    watchCreateProject(),
    takeEvery(projectsSlice.actions.generateExportFetch, generateExportFetch),
    takeEvery(projectsSlice.actions.downloadFilesFetch, downloadFilesFetch),
    takeEvery(
      projectsSlice.actions.changeSectionOrderFetch,
      changeSectionOrderFetch
    ),
  ]);
}

export default projectsSaga;
