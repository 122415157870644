import axios, { AxiosInstance } from "axios";

export default class Auth0Utils {
  private static readonly AXIOS_INSTANCE: AxiosInstance = axios.create({
    baseURL: `https://${process.env.REACT_APP_AUTH0_DOMAIN}`,
  });

  static resetPassword(email: string): Promise<void> {
    return this.AXIOS_INSTANCE.post("/dbconnections/change_password", {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email,
      connection: "Username-Password-Authentication",
    });
  }
}
