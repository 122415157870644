import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../redux/reducer";
import projectsSlice from "../redux/projects/slice";

const useProjects = () => {
  const projects = useSelector<RootState, any>((state) => state.projects);
  const clients = useSelector<RootState, any>((state) => state.clients);
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (projects.data.length === 0) {
      dispatch(projectsSlice.actions.fetch());
    }
  }, []);

  const projectsWithClients = { ...projects };

  if (clients.data.length > 0) {
    projectsWithClients.data = projectsWithClients.data.map((project: any) => {
      const projectWithClientName = { ...project };
      projectWithClientName.client = clients.data.find(
        (client: any) => client.id === project.clientId
      );
      return projectWithClientName;
    });
  }

  return projectsWithClients;
};

export default useProjects;
