import { createUseStyles } from "react-jss";
import { ArrowCounterclockwise } from "react-bootstrap-icons";

interface Props {
  questionnaireId: number;
  setQuestionnaireIdToRevertSubmit: Function;
}

const useStyles = createUseStyles({
  button: {
    width: "1.5rem",
    height: "1.5rem",
    cursor: "pointer",
    borderRadius: "0.75rem",
    backgroundColor: "#ffb500",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalBody: {
    padding: "1rem 4.5rem 4.5rem 4.5rem",
  },
});

const RevertSubmitQuestionnaireButton = ({
  questionnaireId,
  setQuestionnaireIdToRevertSubmit,
}: Props) => {
  const classes = useStyles();

  const onButtonClick = () => setQuestionnaireIdToRevertSubmit(questionnaireId);

  return (
    <div className={classes.button} onClick={onButtonClick}>
      <ArrowCounterclockwise />
    </div>
  );
};

export default RevertSubmitQuestionnaireButton;
