import {
  all,
  actionChannel,
  call,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import sectionsSlice from "./slice";
import { api, httpClient } from "../../utils";

function* findById(sectionId: number) {
  let section = null;
  try {
    const { data } = yield call<any>(
      httpClient.get,
      api.sections.getById.replace(":id", sectionId.toString())
    );
    section = data;
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
  return section;
}

function* fetch() {
  try {
    const { data: sections } = yield call<any>(
      httpClient.get,
      api.sections.get
    );
    yield put(sectionsSlice.actions.addAll(sections));
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* copy({ payload: sectionId }: any): any {
  try {
    const {
      data: { id: sectionCopyId },
    } = yield call<any>(
      httpClient.post,
      api.sections.copy.replace(":id", sectionId)
    );
    const section = yield call(findById, sectionCopyId);
    yield put(sectionsSlice.actions.add(section));
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* copyQuestion({ payload }: any): any {
  const { sectionId, questionId } = payload;
  try {
    const {
      data: { id: newQuestionId },
    } = yield call<any>(
      httpClient.post,
      api.sections.copyQuestion
        .replace(":id", sectionId)
        .replace(":questionId", questionId)
    );

    const updatedSection = yield call(findById, sectionId);
    yield put(
      sectionsSlice.actions.update({
        sectionData: updatedSection,
        newQuestionId,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* update({ payload: { sectionData, callback } }: any) {
  try {
    const { data: updatedSectionData } = yield call<any>(
      httpClient.patch,
      api.sections.update,
      sectionData
    );
    callback();
    yield put(
      sectionsSlice.actions.update({ sectionData: updatedSectionData })
    );
  } catch (error) {
    console.log(error);
    if (error.response?.status === 409) {
      yield put(sectionsSlice.actions.setSectionNameExists(true));
    } else {
      yield put(sectionsSlice.actions.addError(error));
    }
  }
}

function* updateQuestion({
  payload: { questionData, callback, originSectionId },
}: any): any {
  try {
    const { data: updatedQuestionData } = yield call<any>(
      httpClient.patch,
      api.questions.update,
      questionData
    );
    if (callback) {
      callback();
    }

    // If the question was moved to another section
    if (
      questionData.sectionId &&
      originSectionId &&
      questionData.sectionId !== originSectionId
    ) {
      const [updatedOriginSection, updatedTargetSection] = yield all([
        call(findById, originSectionId),
        call(findById, questionData.sectionId),
      ]);
      yield all([
        put(
          sectionsSlice.actions.update({ sectionData: updatedOriginSection })
        ),
        put(
          sectionsSlice.actions.update({ sectionData: updatedTargetSection })
        ),
      ]);
    } else if (questionData.archived) {
      // If the question was archived

      const updatedSection = yield call(findById, originSectionId);
      yield put(sectionsSlice.actions.update(updatedSection));
    } else {
      yield put(sectionsSlice.actions.updateQuestion(updatedQuestionData));
    }
  } catch (error) {
    console.log(error);
    if (error.response?.status === 409) {
      yield put(sectionsSlice.actions.setQuestionNameExists(true));
    } else {
      yield put(sectionsSlice.actions.addError(error));
    }
  }
}

function* updateAnswerOption({ payload: answerOptionData }: any) {
  try {
    yield call<any>(
      httpClient.patch,
      api.answerOptions.update,
      answerOptionData
    );
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* createAnswerOption({ payload: answerOption }: any) {
  try {
    yield call<any>(httpClient.post, api.answerOptions.create, answerOption);
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* archive({ payload: sectionId }: any) {
  try {
    yield call<any>(httpClient.patch, api.sections.update, {
      id: sectionId,
      archived: true,
    });
    yield put(sectionsSlice.actions.remove(sectionId));
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* archiveQuestion({ payload: { questionId, sectionId } }: any): any {
  try {
    yield call<any>(httpClient.patch, api.questions.update, {
      id: questionId,
      archived: true,
    });
    const updatedSection = yield call(findById, sectionId);
    yield put(sectionsSlice.actions.update({ sectionData: updatedSection }));
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* changeOrderFetch({ payload: { section, direction } }: any) {
  if (section.name === "starters") {
    return;
  }
  let {
    sections: { data: sections },
  } = yield select();
  sections = sections.filter((s: any) => s.name !== "starters");
  const sectionIndex = sections.findIndex((s: any) => s.id === section.id);

  // The last and the first cannot be moved outside existing range
  if (
    (sectionIndex === 0 && direction === "UP") ||
    (sectionIndex === sections.length - 1 && direction === "DOWN")
  ) {
    return;
  }
  let theOtherIndex;
  if (direction === "UP") {
    theOtherIndex = sectionIndex - 1;
  } else {
    theOtherIndex = sectionIndex + 1;
  }

  const theOtherSection = sections[theOtherIndex];
  try {
    yield all([
      call<any>(httpClient.patch, api.sections.update, {
        id: section.id,
        sortOrder: theOtherSection.sortOrder,
      }),
      call<any>(httpClient.patch, api.sections.update, {
        id: theOtherSection.id,
        sortOrder: section.sortOrder,
      }),
    ]);
    yield put(
      sectionsSlice.actions.changeOrder({
        section1Index: sectionIndex,
        section2Index: theOtherIndex,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* changeQuestionOrderFetch({
  payload: { section, question, direction },
}: any) {
  const questionIndex = section.questions.findIndex(
    (q: any) => q.id === question.id
  );

  // The last and the first cannot be moved outside existing range
  if (
    (questionIndex === 0 && direction === "UP") ||
    (questionIndex === section.questions.length - 1 && direction === "DOWN")
  ) {
    return;
  }
  let theOtherIndex;
  if (direction === "UP") {
    theOtherIndex = questionIndex - 1;
  } else {
    theOtherIndex = questionIndex + 1;
  }
  const theOtherQuestion = section.questions[theOtherIndex];
  try {
    yield all([
      call<any>(httpClient.patch, api.questions.update, {
        id: question.id,
        sortOrder: theOtherQuestion.sortOrder,
      }),
      call<any>(httpClient.patch, api.questions.update, {
        id: theOtherQuestion.id,
        sortOrder: question.sortOrder,
      }),
    ]);
    yield put(
      sectionsSlice.actions.changeQuestionOrder({
        sectionId: section.id,
        question1Index: questionIndex,
        question2Index: theOtherIndex,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* deleteAnswerOption({ payload: answerOptionId }: any) {
  try {
    yield call<any>(
      httpClient.delete,
      api.answerOptions.delete.replace(":id", answerOptionId)
    );
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* createReflexiveRule({ payload: reflexiveRule }: any) {
  try {
    yield call<any>(httpClient.post, api.reflexiveRules.create, reflexiveRule);
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* updateReflexiveRule({ payload: reflexiveRule }: any) {
  try {
    yield call<any>(httpClient.patch, api.reflexiveRules.update, reflexiveRule);
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* deleteReflexiveRule({ payload: reflexiveRuleId }: any) {
  try {
    yield call<any>(
      httpClient.delete,
      api.reflexiveRules.delete.replace(":id", reflexiveRuleId)
    );
  } catch (error) {
    console.log(error);
    yield put(sectionsSlice.actions.addError(error));
  }
}

function* findQuestionById({ payload: id }: any) {
  const { data: question } = yield call<any>(
    httpClient.get,
    api.questions.findById.replace(":id", id)
  );
  yield put(sectionsSlice.actions.updateQuestion(question));
}

function* watchUpdateQuestion(): any {
  const updateQuestionChannel = yield actionChannel([
    sectionsSlice.actions.updateQuestionFetch.type,
    sectionsSlice.actions.updateAnswerOptionFetch.type,
    sectionsSlice.actions.createAnswerOptionFetch.type,
    sectionsSlice.actions.createAnswerOptionFetch.type,
    sectionsSlice.actions.deleteAnswerOptionFetch.type,
    sectionsSlice.actions.createReflexiveRuleFetch.type,
    sectionsSlice.actions.updateReflexiveRuleFetch.type,
    sectionsSlice.actions.deleteReflexiveRuleFetch.type,
    sectionsSlice.actions.singleQuestionFetch.type,
  ]);

  while (true) {
    const action = yield take(updateQuestionChannel);
    let actionHandler;
    switch (action.type) {
      case sectionsSlice.actions.updateQuestionFetch.type:
        actionHandler = updateQuestion;
        break;
      case sectionsSlice.actions.updateAnswerOptionFetch.type:
        actionHandler = updateAnswerOption;
        break;
      case sectionsSlice.actions.createAnswerOptionFetch.type:
        actionHandler = createAnswerOption;
        break;
      case sectionsSlice.actions.deleteAnswerOptionFetch.type:
        actionHandler = deleteAnswerOption;
        break;
      case sectionsSlice.actions.createReflexiveRuleFetch.type:
        actionHandler = createReflexiveRule;
        break;
      case sectionsSlice.actions.updateReflexiveRuleFetch.type:
        actionHandler = updateReflexiveRule;
        break;
      case sectionsSlice.actions.deleteReflexiveRuleFetch.type:
        actionHandler = deleteReflexiveRule;
        break;
      case sectionsSlice.actions.singleQuestionFetch.type:
        actionHandler = findQuestionById;
        break;
      default:
        return null;
    }
    yield call(actionHandler, action);
  }
}

function* sectionsSaga() {
  yield all([
    takeEvery(sectionsSlice.actions.fetch.type, fetch),
    takeEvery(sectionsSlice.actions.copyFetch.type, copy),
    takeEvery(sectionsSlice.actions.copyQuestionFetch.type, copyQuestion),
    takeEvery(sectionsSlice.actions.updateFetch.type, update),
    takeEvery(sectionsSlice.actions.archiveQuestionFetch.type, archiveQuestion),
    takeEvery(sectionsSlice.actions.archiveFetch.type, archive),
    takeEvery(sectionsSlice.actions.changeOrderFetch.type, changeOrderFetch),
    takeEvery(
      sectionsSlice.actions.changeQuestionOrderFetch.type,
      changeQuestionOrderFetch
    ),
    watchUpdateQuestion(),
  ]);
}

export default sectionsSaga;
